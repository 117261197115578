import React, { Component } from "react";

import GameHeader from "../../components/GameHeader/GameHeader";
import GameHome from "../../components/GameHome/GameHome";

import "./Home.css"
class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="fixGameHeader">
        <GameHeader></GameHeader>
        </div>
        <GameHome></GameHome>
        {/* <Banner></Banner> */}
        {/* <Introduce></Introduce> */}
        {/* <KeyPoint></KeyPoint> */}
      </div>
    );
  }
}
export default Home;
