import React, { Component } from "react";
import "./BlastHome.css";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import GameHeader from "../../components/GameHeader/GameHeader";
import BlastHeroLogo from "../../assets/BlastHeroLogo.png";
class BlastHome extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="BlastHome">
        {/* <div className="fixGameHeader">
          <GameHeader></GameHeader>
        </div> */}

        <div className="BlastHeroBanner">
          <div className="BannerContent">
            <div>
              <div className="BlastHeroLogo">
                {" "}
                <img src={BlastHeroLogo} width="100%" />
              </div>

              <div className="BannerContentTitle">
                Fully on-chain PVP game deployed on Blast
              </div>
              <div className="BannerContentDescription">
                Full gas as winner reward
              </div>
              <div className="BannerContentButtons">
                {/* <a href="https://t.me/+sslD8gEBe1IzNTU1">
                  <div className="BannerContentButton">Telegram</div>
                </a> */}
                <a href="https://twitter.com/BlastHeroGame" target="_blank">
                  <div className="BannerContentButton">Twitter</div>
                </a>
                {/* <div className="BannerContentButton">Mint</div> */}
              </div>
            </div>
          </div>
          <div className="BlastHeroBannerBgMask"></div>
          <div className="BlastHeroBannerBg"></div>
        </div>

        <div className="HomeTitle">How to Play</div>
        <div className="HowPlay">
          <div className="HowPlayItem">
            <img src={icon2} width={48} />
            <div className="HowPlayItemTitle">Mint and Upgrade Heroes</div>
            <div className="HowPlayItemDescription">
              There are 9 different races, each race has 5 levels, and different
              races have different attributes.
            </div>
          </div>

          <div className="HowPlayItem">
            <img src={icon3} width={48} />
            <div className="HowPlayItemTitle">Player vs Player</div>
            <div className="HowPlayItemDescription">
              Freely combine heroes and fight with other players. The winner
              will receive a reward redemption card.
            </div>
          </div>

          <div className="HowPlayItem">
            <img src={icon4} width={48} />
            <div className="HowPlayItemTitle">Reward Redemption</div>
            <div className="HowPlayItemDescription">
              All Gas generated by the game will be deposited into the prize
              pool, and after each season, players can redeem rewards.
            </div>
          </div>
        </div>

        <div className="bg0">
          <div className="bg2"></div>
          <div className="bg1"></div>
          <div className="RoadmapContainer">
            <div className="Roadmap">
              <div className="RoadmapBigTitle">Roadmap</div>
              <div className="RoadmapTitle">Stage 1</div>
              <div className="RoadmapDescription">
                Blast mainnet released. Open Mint and Upgrade Heroes.
              </div>
              <div className="RoadmapTitle">Stage 2</div>
              <div className="RoadmapDescription">
                The first season of on-chain battles begins, and the winner will
                receive rewards.
              </div>
              <div className="RoadmapTitle">Stage 3</div>
              <div className="RoadmapDescription">Blast World of Warcraft</div>
            </div>
          </div>
        </div>

        <div className="BlastFooter">Copyright Blast Hero</div>
      </div>
    );
  }
}
export default BlastHome;
