import Angel from "../assets/heroes/idle/Angel.gif";
import ArchAngel from "../assets/heroes/idle/ArchAngel.gif";
import ArmoredBearRider from "../assets/heroes/idle/ArmoredBearRider.gif";
import Bandit from "../assets/heroes/idle/Bandit.gif";
import BanditThug from "../assets/heroes/idle/BanditThug.gif";
import DeathKnight from "../assets/heroes/idle/DeathKnight.gif";
import DryadDeer from "../assets/heroes/idle/DryadDeer.gif";
import DryadEnchantress from "../assets/heroes/idle/DryadEnchantress.gif";
import ElfArcaneArcher from "../assets/heroes/idle/ElfArcaneArcher.gif";
import ElfHorseman from "../assets/heroes/idle/ElfHorseman.gif";
import Ghost from "../assets/heroes/idle/Ghost.gif";
import Grenadier from "../assets/heroes/idle/Grenadier.gif";
import ClawedDemon from "../assets/heroes/idle/ClawedDemon.gif";
import Demoness from "../assets/heroes/idle/Demoness.gif";
import PrinceMan from "../assets/heroes/idle/PrinceMan.gif";
import SpearMan from "../assets/heroes/idle/SpearMan.gif";
import Orc from "../assets/heroes/idle/Orc.gif";
import WargRider from "../assets/heroes/idle/WargRider.gif";

var Heroes = [
  {
    heroTitle: "Human",
    raceId:0,
    heroDescription:
      "They are the largest race in the Ether continent, very suitable for new players, their attack and defense are medium and average.",
    heroList: [
      {
        heroId: 0,
        heroImage: PrinceMan,
        heroName: "PrinceMan",
        heroStory:"PrinceMan is a noble prince who has a noble appearance and elegant demeanor. He has received a good education and possesses leadership abilities. He strives to serve the people and uphold justice and order.",
        heroCount: 0,
      },
      {
        heroId: 1,
        heroImage: SpearMan,
        heroName: "SpearMan",
        heroStory:"SpearMan is a spearman who excels in using a long spear in combat. He is an important member of the army, using his spear to protect comrades and attack enemies. He requires agility and endurance to survive on the battlefield.",
        heroCount: 0,
      },
    ],
  },
  {
    heroTitle: "Elves",
    raceId:1,
    heroDescription:
      "They have a handsome appearance and high attack power, but their low defense is their weakness.",
    heroList: [
      {
        heroId: 2,
        heroImage: ElfArcaneArcher,
        heroName: "ElfArcaneArcher",
        heroStory:"ElfArcaneArcher is an elven archer who excels in archery skills and magic. He can shoot enemies from afar and enhance his arrows with magic. He is a guardian of the forest, protecting the elven kingdom from external threats.",
        heroCount: 0,
      },
      {
        heroId: 3,
        heroImage: ElfHorseman,
        heroName: "ElfHorseman",
        heroStory:"ElfHorseman is an elven knight who rides an elegant white horse. He is an honorable guard of the elven kingdom, known for his courage and justice. He often collaborates with other races to fight against evil forces.",
        heroCount: 0,
      },
    ],
  },
  {
    heroTitle: "Orcs",
    raceId:2,
    heroDescription:
      "They have a strong body and act as the team's protectors, but their low attack power is their weakness.",
    heroList: [
      {
        heroId: 4,
        heroImage: Orc,
        heroName: "Orc",
        heroStory:"Orc is a strong and fierce creature who has tough skin and sharp teeth. He is a member of savage tribes, known for violence and combat. They usually live in the wilderness, keeping their distance from other races.",
        heroCount: 0,
      },
      {
        heroId: 5,
        heroImage: WargRider,
        heroName: "WargRider",
        heroStory:"WargRider is a warrior who rides a wolf, forming a close bond with the wolves. They run and fight together, forming an invincible alliance. He is a warrior of the Northern tribe, known for their speed and strength.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "Demons",
    raceId:3,
    heroDescription:
      "They are the largest race in the Ether continent, very suitable for new players, their attack and defense are medium and average.",
    heroList: [
      {
        heroId: 6,
        heroImage: Demoness,
        heroName: "Demoness",
        heroStory:"Demoness is an evil female demon who has enchanting beauty and deadly charm. She excels in seducing and deceiving humans, using their weaknesses to gain power. She is one of the most terrifying creatures in hell.",
        heroCount: 0,
      },
      {
        heroId: 7,
        heroImage: ClawedDemon,
        heroName: "ClawedDemon",
        heroStory:"ClawedDemon is a demon warrior who has sharp claws and fiery red eyes. He is a member of the hellish army, serving the demon kingdom. He is cruel and powerful, one of humanity's most terrifying enemies.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "Undeads",
    raceId:4,
    heroDescription:
      "They are like ghosts. Travel freely in the Ether continent. When you invite them to join the team, they will use dark magic to improve the defense of the team.",
    heroList: [
      {
        heroId: 8,
        heroImage: Ghost,
        heroName: "Ghost",
        heroStory:"Ghost is a ghost who has no physical body, only a transparent form. He can pass through walls and become invisible, appearing during the night. He is the soul of the deceased, often wandering in the human world.",
        heroCount: 0,
      },
      {
        heroId: 9,
        heroImage: DeathKnight,
        heroName: "DeathKnight",
        heroStory:"DeathKnight is a death knight who wears black armor and wields a sharp sword. He was once a righteous knight but was resurrected under the power of death and became an immortal demon. He seeks revenge and hopes to lead an endless army to conquer the world.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "Dwarfs",
    raceId:5,
    heroDescription:
      "Although they are short, they are good at sneak attacking opponents.",
    heroList: [
      {
        heroId: 10,
        heroImage: Grenadier,
        heroName: "Grenadier",
        heroStory:"Grenadier is an artilleryman who excels in using bombs and explosives. He is a destroyer on the battlefield, often attacking enemy positions. He requires precise calculation and courage to carry out his tasks.",
        heroCount: 0,
      },
      {
        heroId: 11,
        heroImage: ArmoredBearRider,
        heroName: "ArmoredBearRider",
        heroStory:"ArmoredBearRider is a brave warrior who rides an armored bear. He is a renowned warrior of the Northern tribe, known for his bravery and fearlessness. He has formed a deep bond with bears and uses their power to defend his homeland.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "Foresters",
    raceId:6,
    heroDescription:
      "They live in forests, are vegetarians, and they use natural magic to remove the armor of their opponents.",
    heroList: [
      {
        heroId: 12,
        heroImage: DryadDeer,
        heroName: "DryadDeer",
        heroStory:"DryadDeer is a beautiful dryad deer who lives in a mysterious forest. She is closely connected to nature, having the ability to communicate with plants and animals. She is a guardian of the forest, protecting her territory from destruction.",
        heroCount: 0,
      },
      {
        heroId: 13,
        heroImage: DryadEnchantress,
        heroName: "DryadEnchantress",
        heroStory:"DryadEnchantress is a magical dryad witch who possesses powerful magical abilities. She can manipulate natural elements and use magic for healing and protection. She is a wise and mentor in the forest, helping people solve problems and find answers.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "HolyWarriors",
    raceId:7,
    heroDescription:
      "They exude a divine light, and when illuminated by this light, you will gain confidence and will to fight.",
    heroList: [
      {
        heroId: 14,
        heroImage: Angel,
        heroName: "Angel",
        heroStory:"Angel is an angel who has beautiful wings and a radiant halo. She is a symbol of guardianship and protection, often appearing in the human world to bring hope and blessings to people.",
        heroCount: 0,
      },
      {
        heroId: 15,
        heroImage: ArchAngel,
        heroName: "ArchAngel",
        heroStory:"ArchAngel is a powerful angelic warrior who has enormous wings and a shimmering sword. He is the leader of the heavenly army, fighting against evil forces to protect humans from demonic invasion.",
        heroCount: 0,
      },
    ],
  },{
    heroTitle: "Traitors",
    raceId:8,
    heroDescription:
      "They are traitors who split from the human race. They betrayed the Ether continent for wealth. Be careful when you see them.",
    heroList: [
      {
        heroId: 16,
        heroImage: Bandit,
        heroName: "Bandit",
        heroStory:"Bandit is a clever and cunning thief who excels in stealth and theft. He often appears in the dark of night, targeting the possessions of the wealthy. Despite being a criminal, he has his own moral code and only targets those who are greedy and unjust.",
        heroCount: 0,
      },
      {
        heroId: 17,
        heroImage: BanditThug,
        heroName: "BanditThug",
        heroStory:"BanditThug is a rough and rude henchman of the bandit, he is a brutal thug. He has no principles or morals, living only for money and power. He often acts alongside Bandit, providing him with support and protection.",
        heroCount: 0,
      },
    ],
  }
];

export default Heroes;
