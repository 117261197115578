import HumanLv1 from "../../assets/badge/lv1/Human.png";
import ElvesLv1 from "../../assets/badge/lv1/Elves.png";
import OrcsLv1 from "../../assets/badge/lv1/Orcs.png";
import DemonsLv1 from "../../assets/badge/lv1/Demons.png";
import UndeadsLv1 from "../../assets/badge/lv1/Undeads.png";
import DwarfsLv1 from "../../assets/badge/lv1/Dwarfs.png";
import ForestersLv1 from "../../assets/badge/lv1/Foresters.png";
import HolyWarriorsLv1 from "../../assets/badge/lv1/HolyWarriors.png";
import TraitorsLv1 from "../../assets/badge/lv1/Traitors.png";

import HumanLv2 from "../../assets/badge/lv2/Human.png";
import ElvesLv2 from "../../assets/badge/lv2/Elves.png";
import OrcsLv2 from "../../assets/badge/lv2/Orcs.png";
import DemonsLv2 from "../../assets/badge/lv2/Demons.png";
import UndeadsLv2 from "../../assets/badge/lv2/Undeads.png";
import DwarfsLv2 from "../../assets/badge/lv2/Dwarfs.png";
import ForestersLv2 from "../../assets/badge/lv2/Foresters.png";
import HolyWarriorsLv2 from "../../assets/badge/lv2/HolyWarriors.png";
import TraitorsLv2 from "../../assets/badge/lv2/Traitors.png";

import HumanLv3 from "../../assets/badge/lv3/Human.png";
import ElvesLv3 from "../../assets/badge/lv3/Elves.png";
import OrcsLv3 from "../../assets/badge/lv3/Orcs.png";
import DemonsLv3 from "../../assets/badge/lv3/Demons.png";
import UndeadsLv3 from "../../assets/badge/lv3/Undeads.png";
import DwarfsLv3 from "../../assets/badge/lv3/Dwarfs.png";
import ForestersLv3 from "../../assets/badge/lv3/Foresters.png";
import HolyWarriorsLv3 from "../../assets/badge/lv3/HolyWarriors.png";
import TraitorsLv3 from "../../assets/badge/lv3/Traitors.png";
const Badges = new Map([
  [
    1,
    [
      {
        img: HumanLv1,
        name: "Human",
      },
      {
   
        img: ElvesLv1,
        name: "Elves",
      },
      {

        img: OrcsLv1,
        name: "Orcs",
      },
      {
 
        img: DemonsLv1,
        name: "Demons",
      },
      {

        img: UndeadsLv1,
        name: "Undeads",
      },
      {

        img: DwarfsLv1,
        name: "Dwarfs",
      },
      {

        img: ForestersLv1,
        name: "Foresters",
      },
      {
  
        img: HolyWarriorsLv1,
        name: "HolyWarriors",
      },
      {

        img: TraitorsLv1,
        name: "Traitors",
      }
    ],
  ],[
    2,
    [
      {
        img: HumanLv2,
        name: "Human",
      },
      {
   
        img: ElvesLv2,
        name: "Elves",
      },
      {

        img: OrcsLv2,
        name: "Orcs",
      },
      {
 
        img: DemonsLv2,
        name: "Demons",
      },
      {

        img: UndeadsLv2,
        name: "Undeads",
      },
      {

        img: DwarfsLv2,
        name: "Dwarfs",
      },
      {

        img: ForestersLv2,
        name: "Foresters",
      },
      {
  
        img: HolyWarriorsLv2,
        name: "HolyWarriors",
      },
      {

        img: TraitorsLv2,
        name: "Traitors",
      }
    ],
  ],[
    3,
    [
      {
        img: HumanLv3,
        name: "Human",
      },
      {
   
        img: ElvesLv3,
        name: "Elves",
      },
      {

        img: OrcsLv3,
        name: "Orcs",
      },
      {
 
        img: DemonsLv3,
        name: "Demons",
      },
      {

        img: UndeadsLv3,
        name: "Undeads",
      },
      {

        img: DwarfsLv3,
        name: "Dwarfs",
      },
      {

        img: ForestersLv3,
        name: "Foresters",
      },
      {
  
        img: HolyWarriorsLv3,
        name: "HolyWarriors",
      },
      {

        img: TraitorsLv3,
        name: "Traitors",
      }
    ],
  ],
]);

export default Badges;
