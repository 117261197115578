import React, { Component } from "react";
import "./Heroes.css";
import GameLib from "../../libs/GameLib";
import chainids from "../../libs/chainIds";
import GameHeader from "../../components/GameHeader/GameHeader";
import MyHeroTitleBg from "../../assets/myherotit_bg.png";
import hero_icon0 from "../../assets/hero_icon0.png";
import hero_icon1 from "../../assets/hero_icon1.png";
import hero_icon2 from "../../assets/hero_icon2.png";
import hero_icon3 from "../../assets/hero_icon3.png";
import hero_icon4 from "../../assets/hero_icon4.png";
import hero_icon5 from "../../assets/hero_icon5.png";
import hero_icon6 from "../../assets/hero_icon6.png";
import hero_icon7 from "../../assets/hero_icon7.png";
import spiritIcon from "../../assets/spirit.png";
import spiritNoIcon from "../../assets/spirit_no.png";
import loading from "../../assets/loading.gif";
import success from "../../assets/success.png";
import creatTeam from "../../assets/create_a_team.png";
import { Popover, Select, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import HeroData from "../../libs/HeroData";
class Heroes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      pageLength: 6,
      currentChain: "",
      currentAddress: "",
      heroes: [],
      hideHeroesLoading: false,
      hideTeamsLoading: false,
      hideLoadMore: true,
      hideNoHero: true,

      teamInfo: [
        {
          name: "Attack",
          load: 0,
          color: "#0098DC",
          value: 0,
        },
        {
          name: "Defense",
          load: 0,
          color: "#EA323C",
          value: 0,
        },
        {
          name: "Attack Debuff",
          load: 0,
          color: "#FFC825",
          value: 0 + "%",
        },
        {
          name: "Defense Debuff",
          load: 0,
          color: "#9600DC",
          value: 0 + "%",
        },
      ],
      teamList: [],
      selectTeams: [],
      selectedTeam: "",
      myTeam: [],
      showTeam: false,
      isCreateTeam: false,
      isEditTeam: false,
      teamName: "",
      gameLib: "",
      teamCount: 0,
      raceName: [
        "Human",
        "Elve",
        "Orc",
        "Demon",
        "Undead",
        "Dwarf",
        "Forester",
        "HolyWarrior",
        "Robber",
      ],
    };
  }
  async componentDidMount() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChain;
    for (var chain of chainids) {
      if (chainId == chain.Network.chainId) {
        currentChain = chain;
      }
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var gameLib = new GameLib(currentChain);

    this.setState({
      gameLib: gameLib,
      currentAddress: accounts[0],
    });

    await this.getHeroesData(
      gameLib,
      accounts[0],
      this.state.pageNum,
      this.state.pageLength
    );
    await this.initTeams(gameLib);
  }
  async loadMore() {
    this.setState({
      hideHeroesLoading: false,
      hideLoadMore: true,
    });
    var pageNum = this.state.pageNum + 1;
    await this.getHeroesData(
      this.state.gameLib,
      this.state.currentAddress,
      pageNum,
      this.state.pageLength
    );
  }

  async getHeroesData(gameLib, from, pageNum, pageLength) {
    var hideLoadMore = false;
    this.setState({
      hideHeroesLoading: false,
    });

    var heroesArray = await gameLib.getHeroesByAddress(
      from,
      pageNum,
      pageLength
    );
    var heroes = [];

    for (var item of heroesArray) {
        

      console.log("item",item)

      var attributes = HeroData.get(parseInt(item[1])).attributes;
      heroes.push({
        tokenId: item.tokenId,
        spirit:item.spirit,
        lv: item[0],
        name: this.state.raceName[item[1]],
        image: HeroData.get(parseInt(item[1])).images[parseInt(item[0]) - 1],
        atk: attributes[0] * item[0],
        def: attributes[1] * item[0],
        ab: attributes[2] * item[0],
        db: attributes[3] * item[0],
        ad: attributes[4] * item[0],
        dd: attributes[5] * item[0],
      });
    }

    if (heroes.length < pageLength) {
      hideLoadMore = true;
    }
    if (pageNum > 1) {
      heroes = this.state.heroes.concat(heroes);
    }

    var hideNoHero = true;
    if (pageNum == 1 && heroes.length == 0) {
      hideNoHero = false;
    }

    this.setState({
      pageNum: pageNum,
      heroes: heroes,
      hideHeroesLoading: true,
      hideLoadMore: hideLoadMore,
      hideNoHero: hideNoHero,
    });
  }

  async changeTeams(selectedTeam) {
    var heroes = this.state.teamList[selectedTeam].heroes;
    var myTeam = await this.getTeamMembers(heroes);
    this.setState({
      myTeam: myTeam,
      selectedTeam: selectedTeam,
      isEditTeam: false,
      isCreateTeam: false,
    });
    this.caclTeamInfo();
  }

  async addHeroToTeam(index, teamMember) {
    var myTeam = this.state.myTeam;

    console.log("teamMember", teamMember);

    if (myTeam.length < 3) {
      myTeam.push(teamMember);

      var heros = this.state.heroes;
      heros.splice(index, 1);

      this.setState({
        myTeam: myTeam,
        heros: heros,
      });
    }

    this.caclTeamInfo();
  }

  async removeHeroFromTeam(index) {
    var myTeam = this.state.myTeam;
    var heroes = this.state.heroes;
    heroes.unshift(myTeam[index]);

    myTeam.splice(index, 1);

    this.setState({
      myTeam: myTeam,
      heroes: heroes,
    });

    this.caclTeamInfo();
  }

  caclTeamInfo() {
    var atk = 0;
    var dfe = 0;
    var ab = 0;
    var db = 0;
    var ad = 0;
    var dd = 0;
    var myTeam = this.state.myTeam;

    for (var i = 0; i < myTeam.length; i++) {
      atk = atk + myTeam[i].atk;
      dfe = dfe + myTeam[i].def;
      ab = ab + myTeam[i].ab;
      db = db + myTeam[i].db;
      ad = ad + myTeam[i].ad;
      dd = dd + myTeam[i].dd;
    }

    console.log("atk", atk);

    atk = parseInt(atk + atk * (ab / 100));
    dfe = parseInt(dfe + dfe * (db / 100));

    this.setState({
      teamInfo: [
        {
          name: "Attack(+Attack Buff)",
          load: atk > 100 ? 100 : atk,
          color: "#0098DC",
          value: atk,
        },
        {
          name: "Defense(+Defense Buff)",
          load: dfe > 100 ? 100 : dfe,
          color: "#EA323C",
          value: dfe,
        },
        {
          name: "Attack Debuff",
          load: ad,
          color: "#FFC825",
          value: ad + "%",
        },
        {
          name: "Defense Debuff",
          load: dd,
          color: "#9600DC",
          value: dd + "%",
        },
      ],
    });
  }

  async initTeams(gameLib) {
    var currentAddress = this.state.currentAddress;
    var teamCount = await gameLib.getTeamsCount(currentAddress);
    if (teamCount == 0) {
      this.setState({
        showTeam: false,
        teamCount: teamCount,
        hideTeamsLoading: true,
      });
    } else {
      var selectTeams = [];
      var teams = await gameLib.getMyTeams(currentAddress);

      for (var i = 0; i < teams.length; i++) {
        selectTeams.push({
          label: teams[i].name,
          value: i,
        });
      }

      var myTeam = await this.getTeamMembers(teams[0].heroes);

      this.setState({
        selectTeams: selectTeams,
        selectedTeam: 0,
        teamList: teams,
        myTeam: myTeam,
        showTeam: true,
        isEditTeam: false,
        isCreateTeam: false,
        hideTeamsLoading: true,
      });

      this.caclTeamInfo();
    }
  }

  async getTeamMembers(heroes) {
    var myTeam = [];

    for (var id of heroes) {
      var item = await this.state.gameLib.getHeroById(id);

      var attributes = HeroData.get(parseInt(item[1])).attributes;
      myTeam.push({
        tokenId: parseInt(item.tokenId),
        spirit:parseInt(item.spirit),
        lv: item[0],
        name: this.state.raceName[item[1]],
        image: HeroData.get(parseInt(item[1])).images[parseInt(item[0]) - 1],
        atk: attributes[0] * item[0],
        def: attributes[1] * item[0],
        ab: attributes[2] * item[0],
        db: attributes[3] * item[0],
        ad: attributes[4] * item[0],
        dd: attributes[5] * item[0],
      });
    }

    return myTeam;
  }

  async createNewTeam() {
    this.setState({
      showTeam: true,
      isCreateTeam: true,
      myTeam: [],
      teamName: "",
    });
    setTimeout(() => {
      this.caclTeamInfo();
    }, 1000);
  }

  async teamDone() {
    var teamName = this.state.teamName;
    var myTeam = this.state.myTeam;
    if (teamName == "") {
      toast("Please give the team a name", {
        autoClose: 6000,
        position: "top-center",
        type: "error",
      });
    } else {
      var heroesIds = [];
      for (var i = 0; i < myTeam.length; i++) {
        heroesIds.push(parseInt(myTeam[i].tokenId));
      }

      const toastId = toast(
        <div className="creating">
          <img src={loading} width={48}></img>
          <p>Pending</p>
        </div>,
        {
          closeOnClick: false,
          autoClose: false,
          position: "top-center",
        }
      );

      var result;
      if (this.state.isCreateTeam) {
        result = await this.state.gameLib.createNewTeam(
          this.state.currentAddress,
          teamName,
          heroesIds
        );
      } else if (this.state.isEditTeam) {
        console.log(
          "this.state.teamList[this.state.selectedTeam].id",
          this.state.teamList[this.state.selectedTeam].id
        );
        console.log("heroesIds", heroesIds);
        result = await this.state.gameLib.EditTeam(
          this.state.currentAddress,
          this.state.teamList[this.state.selectedTeam].id,
          teamName,
          heroesIds
        );
      }

      if (result.status) {
        toast.update(toastId, {
          render: (
            <div className="created">
              <img src={success} width={48}></img>
              <div>
                <p>Finished</p>

                {/* <div
                  className="explorerLink"
                  onClick={this.linkExplorer.bind(
                    this,
                    this.state.currentChain.Network.blockExplorerUrls[0] +
                      "/tx/" +
                      result.transactionHash
                  )}
                >
                  {result.transactionHash}
                </div> */}
              </div>
            </div>
          ),
          closeOnClick: false,
        });

        if (this.state.isCreateTeam) {
          await this.initTeams(this.state.gameLib);
        } else if (this.state.isEditTeam) {
          await this.initTeams(this.state.gameLib);
        }
      }
    }
  }

  editTeam() {
    this.setState({
      isEditTeam: true,
      teamName: this.state.teamList[this.state.selectedTeam].name,
    });
  }

  async cancelEditTeam() {
    if (this.state.isEditTeam) {
      var heroes = this.state.teamList[this.state.selectedTeam].heroes;
      var myTeam = await this.getTeamMembers(heroes);
      this.setState({
        myTeam: myTeam,
        isEditTeam: false,
        teamName: "",
      });
      this.caclTeamInfo();
    } else if (this.state.isCreateTeam) {
      if (this.state.teamList.length == 0) {
        this.setState({
          showTeam: false,
          myTeam: [],
          isCreateTeam: false,
          teamName: "",
        });
        this.caclTeamInfo();
      } else {
        var heroes = this.state.teamList[this.state.selectedTeam].heroes;
        var myTeam = await this.getTeamMembers(heroes);
        this.setState({
          myTeam: myTeam,
          isCreateTeam: false,
          teamName: "",
        });
        this.caclTeamInfo();
      }
    }
  }

  async changeTeamName(e) {
    this.setState({
      teamName: e.target.value,
    });
  }

  linkExplorer = (link) => {
    window.open(link, "_blank");
  };

  render() {
    return (
      <div className="heroManage_bg">
        <GameHeader></GameHeader>
        <ToastContainer />
        <div className="heroManage">
          <div className="myteamsContainer">
            <div className="myteams">
              {this.state.hideTeamsLoading ? (
                <div className="myteams_header">
                  <div className="myteams_header_tit">My Teams</div>

                  <div className="myteams_header_select">
                    <Select
                      value={this.state.selectedTeam}
                      placeholder="Select a team"
                      onChange={this.changeTeams.bind(this)}
                      options={this.state.selectTeams}
                    />
                  </div>

                  <div
                    className="myteams_header_btn"
                    onClick={this.createNewTeam.bind(this)}
                  >
                    <img
                      src={creatTeam}
                      width={36}
                      className="myteams_header_btn_img"
                    />
                    <p>New Team</p>
                  </div>
                </div>
              ) : (
                <div className="myheroes_loading">
                  <img src={loading} width="48" />
                </div>
              )}

              {this.state.showTeam ? (
                <div className="myteams_detail">
                  {this.state.isEditTeam == true ||
                  this.state.isCreateTeam == true ? (
                    <div>
                      <div className="myteams_title">Team Name</div>
                      <Input
                        placeholder="Please give your team a name (Maximum length is 20 and Required)"
                        className="myteams_new_input"
                        onChange={this.changeTeamName.bind(this)}
                        maxLength={40}
                        value={this.state.teamName}
                      ></Input>
                    </div>
                  ) : (
                    <div className="myteams_name"></div>
                  )}

                  <div className="myteams_title">Team Members</div>
                  <ul className="myteams_herolist">
                    <li className="myteams_hero">
                      {this.state.myTeam[0] != undefined ? (
                        <div>
                          <div className="myteams_hero_bg">
                            <img src={this.state.myTeam[0].image} width={120} />
                          </div>
                          <div className="myteams_hero_attribute">

                            <p>
                            {this.state.myTeam[0].name} Lv.{this.state.myTeam[0].lv}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              atk:{this.state.myTeam[0].atk}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              def:{this.state.myTeam[0].def}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ab:{this.state.myTeam[0].ab} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              db:{this.state.myTeam[0].db} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ad:{this.state.myTeam[0].ad} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              dd:{this.state.myTeam[0].dd} %
                            </p>
                            <p className={this.state.myTeam[0].spirit!=0?"myteams_hero_attribute_item":"myteams_hero_attribute_item_red"}>
                              spirit:{this.state.myTeam[0].spirit}
                            </p>
                          </div>
                          {this.state.isEditTeam || this.state.isCreateTeam ? (
                            <div
                              className="myteams_hero_remove"
                              onClick={this.removeHeroFromTeam.bind(this, 0)}
                            >
                              Remove
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ) : (
                        <div className="myteams_hero_bg"></div>
                      )}
                    </li>

                    <li className="myteams_hero">
                      {this.state.myTeam[1] != undefined ? (
                        <div>
                          <div className="myteams_hero_bg">
                            <img src={this.state.myTeam[1].image} width={120} />
                          </div>
                          <div className="myteams_hero_attribute">
                          <p>
                            {this.state.myTeam[1].name} Lv.{this.state.myTeam[1].lv}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              atk:{this.state.myTeam[1].atk}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              def:{this.state.myTeam[1].def}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ab:{this.state.myTeam[1].ab} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              db:{this.state.myTeam[1].db} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ad:{this.state.myTeam[1].ad} %
                            </p>
                            <p className="myteams_hero_attribute_item">
                              dd:{this.state.myTeam[1].dd} %
                            </p>
                            <p className={this.state.myTeam[1].spirit!=0?"myteams_hero_attribute_item":"myteams_hero_attribute_item_red"}>
                            spirit:{this.state.myTeam[1].spirit}
                            </p>
                          </div>
                          {this.state.isEditTeam || this.state.isCreateTeam ? (
                            <div
                              className="myteams_hero_remove"
                              onClick={this.removeHeroFromTeam.bind(this, 1)}
                            >
                              Remove
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ) : (
                        <div className="myteams_hero_bg"></div>
                      )}
                    </li>

                    <li className="myteams_hero">
                      {this.state.myTeam[2] != undefined ? (
                        <div>
                          <div className="myteams_hero_bg">
                            <img src={this.state.myTeam[2].image} width={120} />
                          </div>

                          <div className="myteams_hero_attribute">
                          <p>
                            {this.state.myTeam[2].name} Lv.{this.state.myTeam[2].lv}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              atk:{this.state.myTeam[2].atk}
                            </p>
                            <p className="myteams_hero_attribute_item">
                              def:{this.state.myTeam[2].def} 
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ab:{this.state.myTeam[2].ab}%
                            </p>
                            <p className="myteams_hero_attribute_item">
                              db:{this.state.myTeam[2].db}%
                            </p>
                            <p className="myteams_hero_attribute_item">
                              ad:{this.state.myTeam[2].ad}%
                            </p>
                            <p className="myteams_hero_attribute_item">
                              dd:{this.state.myTeam[2].dd}%
                            </p>
                            <p className={this.state.myTeam[2].spirit!=0?"myteams_hero_attribute_item":"myteams_hero_attribute_item_red"}>
                            spirit:{this.state.myTeam[2].spirit}
                            </p>
                          </div>

                          {this.state.isEditTeam || this.state.isCreateTeam ? (
                            <div
                              className="myteams_hero_remove"
                              onClick={this.removeHeroFromTeam.bind(this, 2)}
                            >
                              Remove
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ) : (
                        <div className="myteams_hero_bg"></div>
                      )}
                    </li>
                  </ul>

                  <div className="myteams_info">
                    <div className="myteams_info_title">Team Analysis</div>
                    <ul className="myteams_info_list">
                      {this.state.teamInfo.map((item, index) => (
                        <li key={index}>
                          <div className="myteams_info_name">{item.name}</div>
                          <div className="myteams_info_load">
                            <div
                              className="myteams_info_load_value"
                              style={{
                                width: item.load + "%",
                                backgroundColor: item.color,
                              }}
                            ></div>
                          </div>
                          <div className="myteams_info_value">{item.value}</div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {this.state.isEditTeam || this.state.isCreateTeam ? (
                    <div className="myteam_btns">
                      <div
                        className="myteam_confirm_btn"
                        onClick={this.teamDone.bind(this)}
                      >
                        DONE
                      </div>
                      <div
                        className="myteam_cancel_btn"
                        onClick={this.cancelEditTeam.bind(this)}
                      >
                        CANCEL
                      </div>
                    </div>
                  ) : (
                    <div
                      className="myteam_edit_btn"
                      onClick={this.editTeam.bind(this)}
                    >
                      EDIT TEAM
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="no_team_tip"
                  onClick={this.createNewTeam.bind(this)}
                  hidden={this.state.hideTeamsLoading == false}
                >
                  <p>
                    You haven't created any team yet, please create a new team
                    first.
                  </p>
                  <div className="no_team_tip_btn">Create a Team</div>
                </div>
              )}
            </div>
          </div>

          <div className="myheroContainer">
            <div className="myhero_tit">
              <div className="myhero_tit_text">My Heroes</div>
              {/* <img src={MyHeroTitleBg} width="100%"></img> */}
            </div>
            <div className="noHeros" hidden={this.state.hideNoHero}>
              <div className="noHeros_tip">You don't have any heroes.</div>
              <a href="/mint">
                <div className="myheroes_loadmore">Mint</div>
              </a>
            </div>

            <ul className="myhero">
              {this.state.heroes.map((item, index) => (
                <li key={index}>
                  <img
                    width="60%"
                    src={item.image}
                    className="myhero_img"
                  ></img>

                  {/* <div className="myhero_name">{item.name}</div> */}

                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon0} width={24}></img>
                      <p style={{ textDecoration: "none" }}>RACE</p>
                    </div>
                    <div className="hero_attribute_value">
                      {item.name} Lv.{item.lv}
                    </div>
                  </div>

                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon7} width={24}></img>

                      <Popover
                        title="Spirit"
                        content="Each hero can automatically get 3 spirit points every day, and each battle will consume one spirit point. When the hero's spirit value is 0, the hero will have no effect in the team."
                        trigger="hover"
                      >
                        <p>SPIRIT</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">
                      {item.spirit == 0 ? (
                        <div>
                          <img src={spiritNoIcon} width={12} />
                          <img src={spiritNoIcon} width={12} />
                          <img src={spiritNoIcon} width={12} />
                        </div>
                      ) :item.spirit  == 1 ? (
                        <div>
                          <img src={spiritIcon} width={12} />
                          <img src={spiritNoIcon} width={12} />
                          <img src={spiritNoIcon} width={12} />
                        </div>
                      ) : item.spirit  == 2 ? (
                        <div>
                          <img src={spiritIcon} width={12} />
                          <img src={spiritIcon} width={12} />
                          <img src={spiritNoIcon} width={12} />
                        </div>
                      ) : (
                        <div>
                          <img src={spiritIcon} width={12} />
                          <img src={spiritIcon} width={12} />
                          <img src={spiritIcon} width={12} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon1} width={24}></img>
                      <Popover
                        title="Attack(ATK)"
                        content="Attack power is a basic attribute, high attack power will
                      cause more damage to the opponent team."
                        trigger="hover"
                      >
                        <p>ATK</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.atk}</div>
                  </div>
                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon2} width={24}></img>
                      <Popover
                        title="Defense(DFE)"
                        content="Defense is a basic attribute, and a high defense can
                        resist more damage from the opposing team."
                        trigger="hover"
                      >
                        <p>DFE</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.def}</div>
                  </div>
                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon3} width={24}></img>
                      <Popover
                        title="Attack Buff(AB)"
                        content="Attack Buff can increase the percentage of our team's
                        attack power."
                        trigger="hover"
                      >
                        <p>AB</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.ab}%</div>
                  </div>
                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon4} width={24}></img>
                      <Popover
                        title="Defense Buff(DB)"
                        content="Defense Buff can increase the percentage of our team's
                        defense."
                        trigger="hover"
                      >
                        <p>DB</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.db}%</div>
                  </div>
                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon5} width={24}></img>
                      <Popover
                        title="Attack Debuff(AD)"
                        content="Attack Debuff can reduce the percentage of the opposing
                        team's attack power."
                        trigger="hover"
                      >
                        <p>AD</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.ad}%</div>
                  </div>
                  <div className="hero_attribute">
                    <div className="hero_attribute_tit">
                      <img src={hero_icon6} width={24}></img>
                      <Popover
                        title="Defense Debuff(DD)"
                        content="Defense Debuff can reduce the percentage of the opposing
                        team's defense."
                        trigger="hover"
                      >
                        <p>DD</p>
                      </Popover>
                    </div>
                    <div className="hero_attribute_value">{item.dd}%</div>
                  </div>

                  {this.state.isEditTeam || this.state.isCreateTeam ? (
                    <div
                      className="addToTeam"
                      onClick={this.addHeroToTeam.bind(this, index, item)}
                    >
                      Select
                    </div>
                  ) : (
                    <div></div>
                  )}
                </li>
              ))}

              <div
                hidden={this.state.hideHeroesLoading}
                className="myheroes_loading"
              >
                <img src={loading} width="48" />
              </div>
            </ul>

            <div
              hidden={this.state.hideLoadMore}
              className="myheroes_loadmore"
              onClick={this.loadMore.bind(this)}
            >
              Load More
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Heroes;
