import React, { Component } from "react";

import "./RandomLoading.css";

import Heroes from "../../libs/HeroesJSON";
import loading from "../../assets/loading.gif"
class RandomLoading extends Component {
  constructor(props) {
    super(props);

    var heroes = [];
    for (var i = 0; i < Heroes.length; i++) {
      for (var y = 0; y < Heroes[i].heroList.length; y++) {
        heroes.push(Heroes[i].heroList[y].heroImage);
      }
    }
    var RandomArray=[]
   for(var i=0;i<4;i++){
     var random=Math.floor(Math.random() * ((heroes.length-1) - 1 )) + 1;
     console.log(random);
     RandomArray.push([
        heroes[random-1], heroes[random],heroes[random+1],
     ])
   }

    this.state = {
      RandomArray: RandomArray,
    };
  }

  render() {
    return (
      <div className="randomMatch">
        <div className="lottery">
          {this.state.RandomArray.map((HeroesArray, index) => (
            <div className="item" key={index}>
              {HeroesArray.map((hero, index) => (
                <img src={hero} width={128} key={index} />
              ))}
            </div>
          ))}
        </div>
        <div className="random_text">
            {/* <img src={loading} width={48}/> <br/> */}
            Matching Opponent <br/> Please Wait
        </div>
      </div>
    );
  }
}
export default RandomLoading;
