import React, { Component } from "react";
import "./Badge.css";
import Badges from "./Badges";
import EtherWarBadgeABI from "../../libs/abi/EtherWarBadge.json";
import Web3 from "web3";
import chainids from "../../libs/chainIds";
import GameHeader from "../../components/GameHeader/GameHeader";
class Badge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chainIndex: 0,
      badgeSelect: 0,

      myBadge: "",

      EtherWarBadgeContract: "",
      stage: 1,

      showLoading: false,
      badges: Badges.get(1),
      tokenId:"",
    };
  }

  selectBadge = (index) => {
    this.setState({
      badgeSelect: index,
    });
  };

  async componentDidMount() {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var currentAddress = accounts[0];

    var web3 = new Web3(window.ethereum);
    var EtherWarBadgeContract = new web3.eth.Contract(
      EtherWarBadgeABI,
      chainids[this.state.chainIndex].EtherWarBadge
    );
    var stage = await EtherWarBadgeContract.methods.stage().call();

    this.setState({
      EtherWarBadgeContract: EtherWarBadgeContract,
      stage: stage,
    });

    var balance = await EtherWarBadgeContract.methods
      .balanceOf(currentAddress)
      .call();

    if (balance != 0) {
      await this.getMyBadge(currentAddress);
    }
  }

  mintBadge = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    this.setState({
      showLoading: true,
    });
    var result = await this.state.EtherWarBadgeContract.methods
      .mint(this.state.badgeSelect)
      .send({ from: accounts[0] })
      .on("transactionHash", function (hash) {
        console.info(hash);
        // return hash;
      })
      .on("receipt", function (receipt) {
        return receipt;
      });
    this.setState({
      showLoading: false,
    });

    this.getMyBadge( accounts[0]);
  };

  getMyBadge = async (currentAddress) => {
    var tokenId = await this.state.EtherWarBadgeContract.methods
      .tokenOfOwnerByIndex(currentAddress, 0)
      .call();
    var badgeInfo = await this.state.EtherWarBadgeContract.methods
      .getBadge(tokenId)
      .call();
    var badgeRace = badgeInfo[0];
    var badgeLevel = badgeInfo[1];

    var myBadge = Badges.get(parseInt(badgeLevel))[badgeRace];
    this.setState({
      tokenId:tokenId,
      myBadge: {
        name: myBadge.name,
        level: badgeLevel,
        img: myBadge.img,
      },
    });
  };

  upgradeBadge=async()=>{
    const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
  
      this.setState({
        showLoading: true,
      });
      var result = await this.state.EtherWarBadgeContract.methods
        .upgradeBadge(this.state.tokenId)
        .send({ from: accounts[0] })
        .on("transactionHash", function (hash) {
          console.info(hash);
          // return hash;
        })
        .on("receipt", function (receipt) {
          return receipt;
        });
      this.setState({
        showLoading: false,
      });
  
      this.getMyBadge( accounts[0]);
  }
  render() {
    return (
      <div className="BadgeContainer">
        <GameHeader></GameHeader>
        <div className="BadgeTitle">Ether War Badge Free Mint</div>
        <div className="BadgeNetwork">Scroll Mainnet</div>
        <div className="BadgeDescription">
          We are a member of the Scroll network ecosystem and are actively
          building a full-chain game, with test versions released on scroll's
          alpha and sepolia. To celebrate the launch of the Scroll mainnet, we
          have released the Ether War Badge on the Scroll mainnet. This NFT is
          composed of nine different badges representing the nine races in the
          Ether War Autonomous World. Each badge has three levels, and you can upgrade them at different stages. In the future, you can use them in games.
        </div>
        <ul className="BadgeNotice">
          <li></li>
        </ul>
        <div className="BadgeUpgradeTitle">Badge Upgrade</div>
        <ul className="BadgeUpgrade">
          <li>
            ◆ Before Nov 10, 2023
            <br />
            <p>Badge Public Mint, you will get a Lv.1 badge.</p>
          </li>
          <li>
            ◆ Before Nov 25, 2023
            <br />
            <p>
              At this stage, mint is closed, new addresses cannot mint badges anymore.
              You can upgrade your Lv.1 badge to Lv.2, and the upgraded badge
              will change. Please note that if you miss Lv2, you will not be able to upgrade to Lv3, so please upgrade your badge on time.
            </p>
          </li>
          <li>
            ◆ Before Dec 10, 2023 <br />
            <p>
              At this stage, you can upgrade your badge to the final badge Lv.3.
            </p>
          </li>
        </ul>
        <div className="BadgeUpgradeTitle">Mint Badge</div>
        <div className="BadgeUpgradeDescription">
          Please select a badge. Each address can mint one badge for free.The
          Ether War Badge is not related to scroll office, it is only used in
          games and should not be considered a financial investment product.
        </div>
        <ul className="BadgeList">
          {this.state.badges.map((item, index) => (
            <li key={index} onClick={this.selectBadge.bind(this, index)}>
              <img src={item.img} width={164} />
              {this.state.badgeSelect == index ? (
                <div className="BadgeSelected">Selected</div>
              ) : (
                <p>{item.name}</p>
              )}
            </li>
          ))}
        </ul>

        {this.state.myBadge == "" ? (
             this.state.stage==1 ?(  <div className="BadgeMintButton" onClick={this.mintBadge.bind(this)}>
             Mint
           </div>):(
            <div className="BadgeMintEnd">Public Mint End</div>
           )
        
        ) : (
            <div className="MyBadge">
            <div className="MyBadgeTitle">My Badge</div>
            <img src={this.state.myBadge.img} width={164} />
            <p>{this.state.myBadge.name}</p>
            <p>Lv.{this.state.myBadge.level}</p>

            {this.state.stage > 1 &&
            this.state.myBadge.level == (this.state.stage-1) ? (
              <div className="BadgeMintButton" onClick={this.upgradeBadge.bind(this)}>Upgrade</div>
            ) : (
                this.state.stage==1?(
                    <div className="BadgeUpgradeTip">Please upgrade your badge on Nov 25, 2023</div>
                ):(
                    this.state.stage==2?(
                        <div className="BadgeUpgradeTip">Please upgrade your badge on Dec 10, 2023</div>
                    ):(
                        <div></div>
                    )
                )
            
            )}
          </div>
        )}



        <div className="BadgeLoading" hidden={!this.state.showLoading}>
          Loading
        </div>

        <div className="BadgeTwitter">
          Please follow us on{" "}
          <a href="https://twitter.com/EtherWarGame" target="_blank">
            Twitter
          </a>{" "}
          to get more rewards
        </div>
      </div>
    );
  }
}
export default Badge;
