import React, { Component } from "react";
import web3 from "web3";
import { message } from "antd";
import chainids from "../../libs/chainIds";
import Modal from "react-modal";
import headerWalletIcon from "../../assets/headerwalleticon.png";
import "./Wallet.css";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: "",
      installremind: false,
      networkName: "",
      networkColor: "#FFF",
      connectWalletModalIsOpen: false,
    };
  }
  async componentDidMount() {
    const { ethereum } = window;

    var that = this;

    if (ethereum && ethereum.isMetaMask) {
      await that.initAccount();

      window.ethereum.on("accountsChanged", async function () {
       await that.initAccount();
       window.location.reload(); 
      });
      window.ethereum.on("chainChanged", async function () {
        await that.initNetwork();
        window.location.reload(); 
      });
    } else {

      
    }
  }

  initAccount = async () => {
    var that = this;
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts[0] != null) {
      that.setState({
        currentAddress: accounts[0],
      });
      await this.initNetwork();
    }
  };
  initNetwork = async () => {
    var that = this;
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    // var chainId = web3.utils.hexToNumber(chainIdHex);

    console.log("chainId",chainId)

    for (var index in chainids) {
      if (chainId == chainids[index].Network.chainId) {
        that.setState({
          networkName: chainids[index].NetworkName,
          networkColor: chainids[index].FontColor,
          connectWalletModalIsOpen: false,
        });
        break;
      } else {
        this.setState({
          connectWalletModalIsOpen: true,
        });
      }
    }
  };

  changeNetwork = async (chainIndex) => {
    var params = chainids[chainIndex].Network;
    console.log("params",params)
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: params.chainId }],
      });
      this.setState({
        connectWalletModalIsOpen: false,
      });
    } catch (switchError) {

      if (switchError.code === 4902) {
   
        try {
        
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [params],
          });
        } catch (addError) {
          console.log(addError)
        }
      }
    }
  };

  handleConnect = async () => {
    if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    } else {
      message.warning("Please install metamask and switch to Network.", 3);
    }
  };

  get address() {
    let end = this.state.currentAddress.length;
    let addressShort =
      this.state.currentAddress.substring(0, 5) +
      "..." +
      this.state.currentAddress.substring(end - 4, end);
    return addressShort;
  }
  hideInstallModal = () => {
    this.setState({
      installremind: false,
    });
  };
  closeConnectWalletModal = () => {
    this.setState({
      connectWalletModalIsOpen: false,
    });
  };
  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.connectWalletModalIsOpen}
          ariaHideApp={false}
          className="NetworkModal pixel pixel_white_border"
          overlayClassName="NetworkOverlay"
        >
          <div className="NetworkModalTitle">Please select network</div>

          <ul className="NetworkModalList">
            {chainids.map((item, index) => (
              <li onClick={this.changeNetwork.bind(this, index)} key={index}>
                {item.Network.chainName}
              </li>
            ))}
          </ul>
          <div
            onClick={this.closeConnectWalletModal}
            className="selectNetworkDialog_close"
          >
            Close
          </div>
        </Modal>

        {this.state.currentAddress == "" ? (
          <div className="wallet_btn_bg" onClick={this.handleConnect}>
            <img src={headerWalletIcon} width={32}></img> &nbsp;
            <p>Connect Wallet</p>
          </div>
        ) : (
          <div className="address">
            {/* <span style={{ color: this.state.networkColor }}>
                    {this.state.networkName}
                  </span>
                  &nbsp; */}

            <div className="wallet_btn_bg">
              <img src={headerWalletIcon} width={32}></img>
              &nbsp;
              <p>{this.address}</p>
             
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Wallet;
