var chainids = [
  {
    BlastHero: "0xdA5368BfbAe4d620406111c6B3477bD9ce3cD5eC",
    BlastTeam:"0xeD9abAf7d813D48F6Fc1fc9470761bEA3F19693A",
    BlastHeroReward:"0xb47e8157bb0cB045f94c74c45Ca267D62B00C770",
    BlastGame:"0x32a24D173c73dE9795993efe0c880E7AA867fe51",
    blockSpeed: 3,
    gamesPerDay: 28800,
    Network: {
      chainId: "0x539",
      chainName: "localhost",
      rpcUrls: ["http://127.0.0.1:7545"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://scrollscan.com"],
    },
  },
  {
    EtherWarNFT: "",
    EtherWarGame: "",
    EtherWarBadge: "0x2C81207650511728837a02c2e33C854A59DF9fa9",
    blockSpeed: 3,
    gamesPerDay: 3,
    Network: {
      chainId: "0x82750",
      chainName: "Scroll Mainnet",
      rpcUrls: ["https://rpc.scroll.io"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://scrollscan.com"],
    },
  },
  {
    EtherWarNFT: "0x2C81207650511728837a02c2e33C854A59DF9fa9",
    EtherWarGame: "0xC3aE55d1E33F260BFdE41936d63467028087b3Fb",
    EtherWarToken: "0x21921D6f78a6227e9974c7e08339ec40b300dCB4",
    blockSpeed: 3,
    gamesPerDay: 3,
    Network: {
      chainId: "0x8274f",
      chainName: "Scroll Sepolia Testnet",
      rpcUrls: ["https://sepolia-rpc.scroll.io"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://sepolia.scrollscan.com"],
    },
  }
];
export default chainids;
