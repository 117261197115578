import Web3 from "web3";
import BlastHeroABI from "./abi/BlastHero.json";
import BlastTeamABI from "./abi/BlastTeam.json"
import BlastGameABI from "./abi/BlastGame.json"
class GameLib {
  constructor(chain) {
    this.web3 = new Web3(window.ethereum);
    this.chain = chain;
    this.BlastHeroContract = new this.web3.eth.Contract(
      BlastHeroABI,
      chain.BlastHero
    );
    this.BlastTeamContract= new this.web3.eth.Contract(
      BlastTeamABI,
      chain.BlastTeam
    );
    this.BlastGameContract=new this.web3.eth.Contract(
      BlastGameABI,
      chain.BlastGame
    );

  }

  async whiteListBalance(_sender) {
    var result = await this.BlastHeroContract.methods
      .WhitelistMapping(_sender)
      .call();
    return result;
  }

  async getCost() {
    var cost = await this.BlastHeroContract.methods.cost().call();
    return Web3.utils.fromWei(cost);
  }

  async mint(_characterIdAndAmount, _sender, _value) {
    var result = await this.BlastHeroContract.methods
      .mint(_characterIdAndAmount)
      .send({ from: _sender, value: _value })
      .on("transactionHash", function (hash) {
        console.info(hash);
        // return hash;
      })
      .on("receipt", function (receipt) {
        return receipt;
      });

    return result;
  }




  async getHeroesByAddress(from,pageNum,pageLength) {
    var start= (pageNum-1)*pageLength;
    var max=(pageNum-1)*pageLength+pageLength;
    let token_URIs = [];

    let counts = await this.BlastHeroContract.methods.balanceOf(from).call();
    var totalPageNum;
    if(counts%pageLength==0){
      totalPageNum=counts/pageLength;
    }else{
      totalPageNum=counts/pageLength+1;
    }
    if(pageNum>totalPageNum){
      return []
    }
    if(max>counts){
      max=counts;
    }
    for (let i = start; i < max; i++) {
      var token_id = await this.BlastHeroContract.methods
        .tokenOfOwnerByIndex(from, i)
        .call();
      
      var tokenDetail=await this.BlastHeroContract.methods.getHero(token_id).call();
  
      tokenDetail.tokenId=token_id;

      var spirit=await this.BlastGameContract.methods.getHeroSpirt(token_id).call();
      tokenDetail.spirit=spirit;

      token_URIs.push(tokenDetail);
    }
    return token_URIs;
  }

  async upgradeHero(_sender,token1,token2){
    var result = await this.BlastHeroContract.methods
    .upgrade(token1,token2)
    .send({ from: _sender})
    .on("transactionHash", function (hash) {
      console.info(hash);
      // return hash;
    })
    .on("receipt", function (receipt) {
      return receipt;
    });

    return result;
  }


  async getTeamsCount(_sender){
    var teamsCount=await this.BlastTeamContract.methods.AddressTeamCount(_sender).call();
    return teamsCount;
  }

  async getMyTeams(_sender){
    var teamCount=await this.getTeamsCount(_sender);


    var teams=[];
    for(var i=teamCount-1;i>=0;i--){


      var teamId=await this.BlastTeamContract.methods.AddressIndexTeamMapping(_sender,i).call();


      var team=await this.getTeamById(teamId);
      


      teams.push({
        id:teamId,
        name:team[0],
        heroes:team[1],
        owner:team[2],
        active:team[3]
      });
    }
    return teams;
  }

  

  async getTeamById(_id){
    var team=await this.BlastTeamContract.methods.getTeamById(_id).call();
    return team;
  }

  async createNewTeam(_sender,_name,_teams){


    var isApproved=await this.BlastHeroContract.methods.isApprovedForAll(_sender,this.chain.BlastTeam).call();
    console.log(isApproved);
    if(!isApproved){
      var approved=await this.BlastHeroContract.methods.setApprovalForAll(this.chain.BlastTeam,true).send({ from: _sender});
    }

    var result = await this.BlastTeamContract.methods
    .createTeam(_name,_teams)
    .send({ from: _sender})
    .on("transactionHash", function (hash) {
      console.info(hash);
    })
    .on("receipt", function (receipt) {
      return receipt;
    });
    return result;


  }

  async EditTeam(_sender,_teamId,_name,_teams){

    var result = await this.BlastTeamContract.methods
    .teamManage(_teamId,_name,_teams)
    .send({ from: _sender})
    .on("transactionHash", function (hash) {
      console.info(hash);
    })
    .on("receipt", function (receipt) {
      return receipt;
    });
    return result;

  }


  async getHeroById(tokenId){


    var tokenDetail=await this.BlastHeroContract.methods.getHero(tokenId).call();
    var spirit=await this.BlastGameContract.methods.getHeroSpirt(tokenId).call();
    tokenDetail.tokenId=tokenId;
    tokenDetail.spirit=spirit



    return tokenDetail;


  }



  async startFight(_sender,_teamId){
    var result = await this.BlastGameContract.methods
    .play(_teamId)
    .send({ from: _sender})
    .on("transactionHash", function (hash) {
      console.info(hash);
    })
    .on("receipt", function (receipt) {
      return receipt;
    });
    return result;
  }



}
export default GameLib;
