import React, { Component } from "react";
import GameLib from "../../libs/GameLib";
import chainids from "../../libs/chainIds";

import Web3 from "web3";
import { Input, Spin } from "antd";


import "./Reward.css";

class Reward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameLib: "",
      currentAddress: "",
      myheroes: [],
      token1: "",
      token2: "",
    };
  }

  async componentDidMount() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChain;
    for (var chain of chainids) {
      if (chainId == chain.Network.chainId) {
        currentChain = chain;
      }
    }
    var gameLib = new GameLib(currentChain);
    var accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    this.setState({
      gameLib: gameLib,
      currentAddress: accounts[0],
    });
    // await this.getHeroes(accounts[0], gameLib, 1, 10);
  }

  async getHeroes(from, gameLib, pageNum, pageLength) {
    var heroes = await gameLib.getHeroesByAddress(from, pageNum, pageLength);
    this.setState({
      myheroes: heroes,
    });
    console.log(heroes);
  }


  render() {
    return (
      <div className="Reward">
        <div className="RewardContainer">
            <div className="RewardTitle">Get Reward</div>
            <div className="RewardGasPool">Reward Gas Pool:100 ETH</div>
            <div className="RewardCard">You have 4 Reward Card</div>
            <div className="RewardValue">You wiil get 0.003 ETH</div>
            <div className="ClaimReward">Claim</div>
        </div>

      </div>
    );
  }
}
export default Reward;
