import React, { Component } from "react";
import GameLib from "../../libs/GameLib";
import chainids from "../../libs/chainIds";
import buyHeroIcon1 from "../../assets/buyheroicon1.png";
import buyHeroIcon2 from "../../assets/buyheroicon2.png";
import buyHeroIcon3 from "../../assets/buyheroicon3.png";
import buyHeroIcon4 from "../../assets/buyheroicon4.png";
import buyHeroIcon5 from "../../assets/buyheroicon5.png";
import TwitterIcon from "../../assets/twittericon.png";
import GuildIcon from "../../assets/guildicon.png";
import fire from "../../assets/fire.gif";
import Web3 from "web3";
import { Input, Spin } from "antd";
import loading from "../../assets/loading.gif";
import success from "../../assets/success.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MintHero.css";
import {BigNumber} from "bignumber.js"
import HeroData from "../../libs/HeroData";

class MintHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxMint: 20,
      heroPrice: 0.002,
      totalPrice: 0,
      totalHeroes: 0,
      freeMint: 0,
      heroes: Array.from(HeroData),
      gameLib: "",
      currentChain: "",
      buyList:[0,0,0,0,0,0,0,0,0]
    };
  }

  componentDidMount() {
    console.log(Array.from(HeroData));

    this.queryWhitelistBalance();
  }

  addHeroCount = (index) => {

    var buyList=this.state.buyList
    buyList[index]=buyList[index]+1
    this.setState({
      buyList:buyList
    })
    this.calBuyHeroesAmount();
  };

  reduceHeroCount = (index) => {
    var buyList=this.state.buyList
    if(buyList[index]!=0){
      buyList[index]=buyList[index]-1;
      this.setState({
        buyList:buyList
      })
    }
    this.calBuyHeroesAmount();
  };

  changeHeroCount = (index,e) => {
    var buyList=this.state.buyList
    if(isNaN(e.target.value)){
  
      buyList[index]=0

    }else{

      buyList[index]=parseInt(e.target.value)

    }

    this.setState({
      buyList:buyList
    })

    console.log(buyList)

    this.calBuyHeroesAmount();
  };

  linkExplorer = (link) => {
    window.open(link, "_blank");
  };

  checkout = async () => {


    if (
      this.state.totalHeroes == 0 ||
      this.state.totalHeroes >= this.state.maxMint
    ) {
      toast("Please mint at least one hero, no more than 20 heroes.", {
        autoClose: 6000,
        position: "top-center",
        type: "error",
      });
    } else {
      var buyList=this.state.buyList;
      var buyHeroArray=[]
      for(var i=0;i<buyList.length;i++){
        if(buyList[i]!=0){
          buyHeroArray.push([i,buyList[i]])
        }
      }
      const toastId = toast(
        <div className="minting">
          <img src={loading} width={48}></img>
          <p>Minting</p>
        </div>,
        {
          closeOnClick: false,
          autoClose: false,
          position: "top-center",
        }
      );

      var _value = Web3.utils.toWei(this.state.totalPrice.toString(), "ether");
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });





      
    try {
      var result = await this.state.gameLib.mint(
        buyHeroArray,
        accounts[0],
        _value
      );
      if (result.status) {
        toast.update(toastId, {
          render: (
            <div className="minted">
              <img src={success} width={48}></img>
              <div>
                <p>Minted</p>

                <div
                  className="explorerLink"
                  onClick={this.linkExplorer.bind(
                    this,
                    this.state.currentChain.Network.blockExplorerUrls[0] +
                      "/tx/" +
                      result.transactionHash
                  )}
                >
                  {result.transactionHash}
                </div>
              </div>
            </div>
          ),
          closeOnClick: false,
        });
        this.clearSelected();
      }
    } catch (error) {
      toast.update(toastId, {
        render: (
          <div className="minted">
            
            <div>
              <p>Mint Fail</p>

            </div>
          </div>
        ),
        closeOnClick: false,
      });
    }

 
    }
  };

  calBuyHeroesAmount = () => {
    var totalAmount=0;
    var buyList=this.state.buyList;
    for(var item of buyList){
      totalAmount=totalAmount+parseInt(item)
    }
    var freeMint = this.state.freeMint;
    var amount = totalAmount - freeMint;
    amount=new BigNumber(amount)

    console.log(amount.toString())
    this.setState({
      totalPrice:
        freeMint >= totalAmount
          ? 0
          : amount.multipliedBy(this.state.heroPrice).toString(),
      totalHeroes: totalAmount,
    });
  };

  async queryWhitelistBalance() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChain;
    for (var chain of chainids) {
      if (chainId == chain.Network.chainId) {
        currentChain = chain;
      }
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    var gameLib = new GameLib(currentChain);


    var freeMint = await gameLib.whiteListBalance(accounts[0]);


    var cost = await gameLib.getCost();

    this.setState({
      currentChain: currentChain,
      freeMint: freeMint,
      gameLib: gameLib,
      heroPrice: cost,
    });
  }

  async clearSelected() {
    await this.queryWhitelistBalance();

    this.setState({
      totalPrice: 0,
      totalHeroes: 0,
      buyList:[0,0,0,0,0,0,0,0,0]
    });
  }

  render() {
    return (
      <div className="buyHeroes_bg">
        <ToastContainer />
        <div className="buyHeroes_title">
          Mint Heroes
          <span className="buyHeroes_title_tip">Testnet</span>
        </div>
        <div className="buyHeroes_description pixel2 pixel_white_border">
          EtherWar consists of 9 races and 100+ heroes. Heroes of different
          races have different attributes.
        </div>
        <div className="buyHeroes">
          {this.state.heroes.map((item, index) => (
            <div className="heroItem" key={index}>
              <div className="heroTitle"> {item[1].race}</div>
              {/* <div className="heroDescription">
                {item[1].title}
              </div> */}
              <div className="heroImage">
                <img src={item[1].images[0]} width={164}></img>
              </div>
              <div className="heroName">Lv.1</div>

              <div className="buyHeroCount">
                <div
                  className="buyHeroCountBtn"
                  onClick={this.addHeroCount.bind(
                    this,
                    index
                  )}
                >
                  +
                </div>

                <Input
                  className="buyHeroCount_input "
                  defaultValue={0}
                  value={this.state.buyList[index]}
                  onChange={this.changeHeroCount.bind(this,index)}
                ></Input>

                <div
                  className="buyHeroCountBtn"
                  onClick={this.reduceHeroCount.bind(
                    this,
                    index
                  )}
                >
                  -
                </div>
              </div> 
            </div>
          ))}
        </div>

        <div className="payInfo">
          <ul className="summary">
            <li>
              <img src={buyHeroIcon1} width={32}></img>
              <p>
                Max Mint Per Transaction:&nbsp;&nbsp;
                <span className="buyHeroYellowColor">{this.state.maxMint}</span>
              </p>
            </li>
            <li>
              <img src={buyHeroIcon2} width={32}></img>{" "}
              <p>
                Hero Pirce: &nbsp;&nbsp;
                <span className="buyHeroYellowColor">
                  {this.state.heroPrice}&nbsp;
                  {this.state.currentChain == "" ? (
                    <span></span>
                  ) : (
                    <span>
                      {this.state.currentChain.Network.nativeCurrency.name}
                    </span>
                  )}
                </span>
              </p>
            </li>
            <li>
              <img src={buyHeroIcon3} width={32}></img>
              <p>
                Selected Quantity:&nbsp;&nbsp;
                <span className="buyHeroYellowColor">
                  {this.state.totalHeroes}
                </span>
                <span>
                  {this.state.totalHeroes > this.state.maxMint ? (
                    <span className="buyHeroRedColor">
                      &nbsp;&nbsp; Max Mint 20 Heroes Per Transaction
                    </span>
                  ) : (
                    <span></span>
                  )}
                </span>
              </p>
            </li>
            <li>
              <img src={buyHeroIcon5} width={32}></img>
              <p>
                Free mint for supporters:&nbsp;&nbsp;
                <span className="buyHeroYellowColor">
                  {this.state.freeMint}
                </span>
                <span>
                  <span className="buyHeroRedColor">&nbsp;&nbsp;</span>
                </span>
              </p>
            </li>
            <li>
              <img src={buyHeroIcon4} width={32}></img>
              <p>
                Total Price:&nbsp;&nbsp;
                <span className="buyHeroYellowColor">
                  {this.state.totalPrice} &nbsp;
                  {this.state.currentChain == "" ? (
                    <span></span>
                  ) : (
                    <span>
                      {this.state.currentChain.Network.nativeCurrency.name}
                    </span>
                  )}
                </span>
              </p>
            </li>
          </ul>

          <div className="buyHeroCheckoutBtn" onClick={this.checkout}>
            Checkout
          </div>
        </div>
        <ul className="social_link_white">
          <li>
            <a href="https://twitter.com/EtherWarGame" target="_blank">
              <img src={TwitterIcon} width={36} />
              <p>Twitter</p>
            </a>
          </li>
          <li>
            <a href="https://guild.xyz/etherwar" target="_blank">
              <img src={GuildIcon} width={32} />
              <p>Guild</p>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
export default MintHero;
