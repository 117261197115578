import React, { Component } from "react";
import elements from "./Elements";
import "./Land.css";
class Land extends Component {
  constructor(props) {
    super(props);
    var landMap=[];
    for(var i=0;i<400;i++){
        landMap.push(i)
    }
    this.state={
        landMap:landMap 
    }   
    console.log(elements)
  }
  render() {
    return (
      <div>
        <ul className="landMap">
            {this.state.landMap.map((item,index)=>(
                   <li className="landMap_item" key={index}>{index}</li>
            ))}
         
        </ul>
        <div></div>
      </div>
    );
  }
}
export default Land;
