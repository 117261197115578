import React, { Component } from "react";
import { Link } from "react-router-dom";
import Wallet from "../Wallet/Wallet";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./GameHeader.css";
import logo from "../../assets/logo.png";
import headerMenuIcon from "../../assets/headermenuicon.png";

class GameHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="GameHeaderBg">
        <div className="GamerHeader">
          <div className="GamerHeaderLogo">
            <img src={logo} width={200}></img>
          </div>

          <div className="GamerHeaderWalletMenu">
            <Wallet></Wallet>

            <Menu
              align="center"
              gap={6}
              menuButton={
                <div className="GameMenuButton">
                  <img width={32} src={headerMenuIcon}></img>&nbsp;Menu
                </div>
              }
              transition
              menuClassName="gamemenu"
            >
              <Link to="/">
                <MenuItem> Home </MenuItem>
              </Link>

              <Link to="/badge">
                <MenuItem>Badge(Mainnet)</MenuItem>
              </Link>

              <Link to="/mint">
                <MenuItem>Mint Hero</MenuItem>
              </Link>

              <Link to="/heroes">
                <MenuItem>My Heroes</MenuItem>
              </Link>

              <Link to="/fight">
                <MenuItem>Fight</MenuItem>
              </Link>

          
            </Menu>
          
          
          </div>
        </div>
      </div>
    );
  }
}
export default GameHeader;
