import Home from "../pages/Home/Home";
import Mint from "../pages/Mint/Mint";
import Heroes from "../pages/Heroes/Heroes";
import OwnerManager from "../pages/OwnerManager/OwnerManager";
import Fight from "../pages/Fight/Fight";
import Land from "../components/Land/Land";
import Badge from "../pages/Badge/Badge"
import UpgradeHero from "../pages/UpgradeHero/UpgradeHero"
import Reward from "../pages/Reward/Reward";
import BlastHome from "../pages/BlastHome/BlastHome";
const routes = [
  {
    path: "/",
    component: BlastHome,
    exact: true,
  },
  {
    path: "/mint",
    component: Mint,
    exact: true,
  },
  {
    path: "/heroes",
    component: Heroes,
    exact: true,
  },
  {
    path: "/OwnerManager",
    component: OwnerManager,
    exact: true,
  },{
    path:"/Fight",
    component:Fight,
    exact:true
  },{
    path:"/Land",
    component:Land,

  },{
    path:"/Badge",
    component:Badge,
  },{
    path:"/UpgradeHero",
    component:UpgradeHero,
  },{
    path:"/Reward",
    component:Reward,
  }
];
export default routes;
