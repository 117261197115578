import React, { Component } from "react";
import MintHero from "../../components/MintHero/MintHero"
import GameHeader from "../../components/GameHeader/GameHeader";
class Mint extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div >
        <GameHeader></GameHeader>
        <MintHero></MintHero>
      </div>
    );
  }
}
export default Mint;
