import React, { Component } from "react";
import GameHeader from "../../components/GameHeader/GameHeader";
import fightVS from "../../assets/fightVS.png";
import victory from "../../assets/victory.png";
import defeat from "../../assets/defeat.png";
import draw from "../../assets/draw.png";
import noOpponent from "../../assets/noOpponent.png";
import { Select } from "antd";
import "./Fight.css";
import chainids from "../../libs/chainIds";
import hits4 from "../../assets/hits4.gif";
import RandomLoading from "../../components/RandomLoading/RandomLoading";
import loading from "../../assets/loading.gif";
import success from "../../assets/success.png"
import { ToastContainer, toast } from "react-toastify";
import GameLib from "../../libs/GameLib";
import HeroData from "../../libs/HeroData";
class Fight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: "",
      myTeam: [],
      teamList: [],
      selectTeams: [],
      selectedTeam: [],
      team1Info: [],
      team2Info: [],
      opponentTeamName: "",
      opponentTeam: [],
      points: 0,
      showFightResult: false,
      showAnimate: false,
      showRandom: false,
      showTeam1Loading:true,
      showNoteam:false,
      fightResult: 0,
      raceName: [
        "Human",
        "Elve",
        "Orc",
        "Demon",
        "Undead",
        "Dwarf",
        "Forester",
        "HolyWarrior",
        "Robber",
      ]
    };
  }

  async componentDidMount() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChain;
    for (var chain of chainids) {
      if (chainId == chain.Network.chainId) {
        currentChain = chain;
      }
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });



    var gameLib = new GameLib(currentChain);
    this.setState({
      currentChain: currentChain,
      currentAddress: accounts[0],
      gameLib: gameLib,
    });

    await this.initTeams(gameLib);
    // await this.initPoints();

    // await this.getOpponent(2);
  }

  async initTeams(gameLib) {
    var currentAddress = this.state.currentAddress;
    var teamCount = await gameLib.getTeamsCount(currentAddress);
    if (teamCount == 0) {
      this.setState({
        showTeam: false,
        teamCount: teamCount,
        showNoteam:true,
        showTeam1Loading:false
      });
    } else {
      var selectTeams = [];
      var teams = await gameLib.getMyTeams(currentAddress);

      for (var i = 0; i < teams.length; i++) {
        selectTeams.push({
          label: teams[i].name,
          value: i,
        });
      }

      console.log("teams",teams[0].heroes)

      var myTeam = await this.getTeamMembers(teams[0].heroes);

 

     var teamInfo = this.caclTeamInfo(myTeam);

      this.setState({
        selectTeams: selectTeams,
        selectedTeam: 0,
        teamList: teams,
        myTeam: myTeam,
        showTeam1Loading:false,
          team1Info: teamInfo,
      });
    }
  }

  caclTeamInfo(myTeam) {
    var atk = 0;
    var dfe = 0;
    var ab = 0;
    var db = 0;
    var ad = 0;
    var dd = 0;

    for (var i = 0; i < myTeam.length; i++) {

      console.log("spirit",myTeam[i].spirit)

      if(myTeam[i].spirit!=0){
        atk = atk + myTeam[i].atk;
        dfe = dfe + myTeam[i].def;
        ab = ab + myTeam[i].ab;
        db = db + myTeam[i].db;
        ad = ad + myTeam[i].ad;
        dd = dd + myTeam[i].dd;
      }
    }
    atk = parseInt(atk + atk * (ab / 100));
    dfe = parseInt(dfe + dfe * (db / 100));

    var teamInfo = [
      {
        name: "Attack(+Attack Buff)",
        load: atk > 100 ? 100 : atk,
        color: "#0098DC",
        value: atk,
      },
      {
        name: "Defense(+Defense Buff)",
        load: dfe > 100 ? 100 : dfe,
        color: "#EA323C",
        value: dfe,
      },
      {
        name: "Attack Debuff",
        load: ad,
        color: "#FFC825",
        value: ad + "%",
      },
      {
        name: "Defense Debuff",
        load: dd,
        color: "#9600DC",
        value: dd + "%",
      },
    ];
    return teamInfo;
  }

  async changeTeams(selectedTeam) {
    var heroes = this.state.teamList[selectedTeam].heroes;
    var myTeam = await this.getTeamMembers(heroes);
    var teamInfo = this.caclTeamInfo(myTeam);
    this.setState({
      myTeam: myTeam,
      selectedTeam: selectedTeam,
      isEditTeam: false,
      isCreateTeam: false,
      team1Info: teamInfo,
    });
  }



  async getTeamMembers(heroes) {
    var myTeam = [];

    for (var id of heroes) {
      var item = await this.state.gameLib.getHeroById(id);

      var attributes = HeroData.get(parseInt(item[1])).attributes;

      console.log(item[0]);

      myTeam.push({
        tokenId: parseInt(item.tokenId),
        spirit:parseInt(item.spirit),
        lv: item[0],
        name: this.state.raceName[item[1]],
        image: HeroData.get(parseInt(item[1])).images[parseInt(item[0]) - 1],
        atk: attributes[0] * item[0],
        def: attributes[1] * item[0],
        ab: attributes[2] * item[0],
        db: attributes[3] * item[0],
        ad: attributes[4] * item[0],
        dd: attributes[5] * item[0],
      });
    }

    return myTeam;
  }
  



  get address() {
    let end = this.state.currentAddress.length;
    let addressShort =
      this.state.currentAddress.substring(0, 5) +
      "..." +
      this.state.currentAddress.substring(end - 4, end);
    return addressShort;
  }

  async getOpponent(teamId) {



    var opponentTeamInfo = await this.state.gameLib.getTeamById(teamId);



    var opponentTeam =await this.getTeamMembers(opponentTeamInfo[1]);

    console.log("opponentTeam",opponentTeam)

   var team2Info = this.caclTeamInfo(opponentTeam);


    this.setState({
      opponentTeamName: opponentTeamInfo[0],
      opponentTeam: opponentTeam,
      team2Info: team2Info,
    });


  }

  async fight() {

    const toastId = toast(
      <div className="minting">
        <img src={loading} width={48}></img>
        <p>Matching</p>
      </div>,
      {
        closeOnClick: false,
        autoClose: false,
        position: "top-center",
      }
    );

    this.setState({
      showFightResult: false,
      showRandom: true,
      opponentTeamName: "",
      opponentTeam: [],
    });
    var team1Id = this.state.teamList[this.state.selectedTeam].id;
    var result = await this.state.gameLib.startFight(
      this.state.currentAddress,
      team1Id
    );

    if (result.status) {
      toast.update(toastId, {
        render: (
          <div className="minted">
            <img src={success} width={48}></img>
            <div>
              <p>Matched</p>
            </div>
          </div>
        ),
        autoClose: 1500 
      });
    }




    var team2Id = result.events.WarEvent.returnValues.teamB;

    var winner = result.events.WarEvent.returnValues.winner;

    console.log("winner", winner);

    await this.getOpponent(team2Id);



    this.setState({
      fightResult: 3,
      showFightResult: true,
      showAnimate: true,
      showRandom: false,
    });

    new Promise((resolve) =>
      setTimeout(() => {
        this.setState({
          fightResult: winner,
          showFightResult: true,
          showAnimate: false,
        });
      }, 5000)
    );

  }



  render() {
    return (
      <div className="battlebg">
        <ToastContainer />
        <GameHeader></GameHeader>
        <div className="battle">
          <div className="team1">
            <div className="team1_select">
              <div
                className="team1_select_title"
                hidden={this.state.teamList.length == 0}
              >
                Team
              </div>

              <div
                hidden={this.state.teamList.length == 0}
                className="myteams_header_select"
              >
                <Select
                  value={this.state.selectedTeam}
                  placeholder="Select a team"
                  onChange={this.changeTeams.bind(this)}
                  options={this.state.selectTeams}
                />
              </div>
            </div>

            <div hidden={!this.state.showNoteam} className="noteam_tip">
              <p> You don't have any team, please create a team first</p>
              <a href="/heroes">
                <div className="noteam_create_btn">Create a Team</div>
              </a>
            </div>

            <div className="team1_loading" hidden={!this.state.showTeam1Loading}>
            <img src={loading} width="48" />
            </div>


            <ul className="team1_herolist">
              {this.state.myTeam.map((item, index) => (
                <li key={index} style={{ paddingRight: index * 100 }}>
                  <img src={item.image} width={128} />
                  <p>{item.name} Lv.{item.lv}</p>
                </li>
              ))}
            </ul>

            {this.state.myTeam.length != 0 ? (
              <ul className="team1_analysis">
                {this.state.team1Info.map((item, index) => (
                  <li key={index}>
                    <div className="team1_analysis_name">{item.name}</div>

                    <div className="team1_analysis_value">{item.value}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
          <div className="battleshow">
            {this.state.showFightResult ? (
              <div>
                {this.state.fightResult == 0 ? (
                  <img src={victory} width={200} />
                ) : this.state.fightResult == 1 ? (
                  <img src={defeat} width={200} />
                ) : this.state.fightResult == 2 ? (
                  <img src={draw} width={200} />
                ) : (
                  <img src={hits4} width={180} />
                )}
              </div>
            ) : (
              <div className="battleshow_vs">
                <img src={fightVS} width={140} />
              </div>
            )}

            <div
              hidden={
                this.state.fightResult == 3 || this.state.myTeam.length == 0
              }
              className="battleshow_fight_btn"
              onClick={this.fight.bind(this)}
            >
              Fight!
            </div>
          </div>
          <div className="team2">
            {this.state.showRandom ? (
              <RandomLoading></RandomLoading>
            ) : (
              <div>
                <div className="team2_title">
                  <div className="team2_title_text">Your Opponent</div>
                  <div className="team2_name">
                    {this.state.opponentTeamName}
                  </div>
                </div>
                {this.state.opponentTeam.length == 0 ? (
                  <div className="noOpponent">
                    <img src={noOpponent} width={72} />
                  </div>
                ) : (
                  <div>
                    <ul className="team2_herolist">
                      {this.state.opponentTeam.map((item, index) => (
                        <li key={index} style={{ paddingLeft: index * 100 }}>
                   

                          <img src={item.image} width={128} />
                          <p>{item.name} Lv.{item.lv}</p>
                        </li>
                      ))}
                    </ul>
                    <ul className="team1_analysis">
                      {this.state.team2Info.map((item, index) => (
                        <li key={index}>
                          <div className="team1_analysis_name">{item.name}</div>

                          <div className="team1_analysis_value">
                            {item.value}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Fight;
