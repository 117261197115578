import React, { Component } from "react";
import axios from "axios";
import HeroesJSON from "../../libs/HeroesJSON";
import Web3 from "web3";




class OwnerManager extends Component {
  constructor(props) {
    super(props);
  }
  generateHero = () => {
    var callArray = [];
    var imageURL =
      "https://bafybeih25x66sw7quytwthw6pbxe7h2fj4hpg4jbohzo2ofbqiiw3horim.ipfs.nftstorage.link/";
    for (var i = 0; i < HeroesJSON.length; i++) {
      var heroes = HeroesJSON[i].heroList;
      for (var y = 0; y < heroes.length; y++) {
        callArray.push([
          0,
          HeroesJSON[i].raceId,
          heroes[y].heroName,
          heroes[y].heroStory,
          imageURL + heroes[y].heroName + ".gif",
        ]);
      }
    }
    console.log(callArray)
  };

  async getTokensHolder(){
    var url="https://blockscout.scroll.io/api?module=token&action=getTokenHolders&contractaddress=0xdf9d7989cd240f74f03945e424e5873a2a5ac163&page=1&offset=122"
    var holders=(await axios.get(url)).data.result;
    var holdersArray=[]
    for(var holder of holders){
      holdersArray.push([holder.address,holder.value])
    }
    console.log(JSON.stringify(holdersArray));
  }

  async getStringToBytes(){
    console.log(Web3.utils.stringToHex("hello"))
  }

  // async encode(){
  //   var encodeData=Web3.utils.encodePacked(
  //     {value: 32, type: 'uint256'},
  //     {value: 32, type: 'uint256'},
  //   )
  //   console.log(encodeData);
  //   // console.log("encodeData",encodeData)
  //   // console.log(Web3.utils.soliditySha3("['address','address']","['0x2C81207650511728837a02c2e33C854A59DF9fa9','0xC3aE55d1E33F260BFdE41936d63467028087b3Fb']"))
  // }

  async encode(){
  
    var web3=new Web3();
    const encode=  web3.eth.abi.encodeParameters(['uint256','uint256'],[1,15]);
    console.log("encode",encode)
   const decoded = web3.eth.abi.decodeParameters(['uint256','uint256'],encode );
    console.log(decoded)
    // Web3.utils..decode("0x000000000000000000000000000000000000000000000000000000000000002a")
  }
  render() {
    return (
      <div>
        <button onClick={this.getTokensHolder}>Get Token Holders</button>
        <button onClick={this.generateHero}>Generate Hero Contract Data</button>
        <button onClick={this.getStringToBytes}>String To Bytes</button>
        <button onClick={this.encode}>Packed</button>
      </div>
    );
  }
}
export default OwnerManager;
