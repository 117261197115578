import React, { Component } from "react";
import GameLib from "../../libs/GameLib";
import chainids from "../../libs/chainIds";

import Web3 from "web3";
import { Input, Spin } from "antd";
import loading from "../../assets/loading.gif";
import success from "../../assets/success.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UpgradeHero.css";
import { BigNumber } from "bignumber.js";
import HeroData from "../../libs/HeroData";
import testheroimage from "../../assets/heroes/Demons/1.gif";

class UpgradeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameLib: "",
      currentAddress: "",
      myheroes: [],
      token1: "",
      token2: "",
    };
  }

  async componentDidMount() {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChain;
    for (var chain of chainids) {
      if (chainId == chain.Network.chainId) {
        currentChain = chain;
      }
    }
    var gameLib = new GameLib(currentChain);
    var accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    this.setState({
      gameLib: gameLib,
      currentAddress: accounts[0],
    });
    await this.getHeroes(accounts[0], gameLib, 1, 10);
  }

  async getHeroes(from, gameLib, pageNum, pageLength) {
    var heroes = await gameLib.getHeroesByAddress(from, pageNum, pageLength);
    this.setState({
      myheroes: heroes,
    });
    console.log(heroes);
  }

  selectHero = (index) => {
    var hero = this.state.myheroes[index];

    var myheroes = this.state.myheroes;

    if (this.state.token1 == "") {
      myheroes.splice(index, 1);
      this.setState({
        token1: hero,
      });
      console.log(1);
    } else {
      if (this.state.token2 == "") {
        myheroes.splice(index, 1);
        this.setState({
          token2: hero,
        });
        console.log(2);
      }
    }
  };

  removeHero = (token) => {
    var myheroes = this.state.myheroes;

    if (token == 1) {
      myheroes.push(this.state.token1);
      this.setState({
        token1: "",
        myheroes: myheroes,
      });
    } else {
      myheroes.push(this.state.token2);
      this.setState({
        token2: "",
        myheroes: myheroes,
      });
    }
  };

  upgrade = async() => {
    var token1=this.state.token1;
    var token2=this.state.token2;
    var currentAddress=this.state.currentAddress;
    await this.state.gameLib.upgradeHero(currentAddress,token1.tokenId,token2.tokenId);
  };

  render() {
    return (
      <div className="upgrade_bg">
        <ToastContainer />
        <div className="upgrade_title">
          Mint Heroes
          <span className="upgrade_title_tip">Testnet</span>
        </div>
        <div className="upgrade_description pixel2 pixel_white_border">
          EtherWar consists of 9 races and 100+ heroes. Heroes of different
          races have different attributes.
        </div>

        <div className="upgrade">
          <div className="upgrade_left">
            <p className="upgrade_tit">Upgrade</p>
            <div className="upgradeTokenA">
              <p className="upgradeTokenATitle">Hero 1</p>
              {this.state.token1 != "" ? (
                <div className="upgradeTokenAInfo">
                  <img
                    src={
                      HeroData.get(parseInt(this.state.token1[1])).images[
                        parseInt(this.state.token1[0])-1
                      ]
                    }
                    className="upgradeTokenAImage"
                  />
                  <div className="upgradeTokenADetail">
                    <div>{this.state.token1.tokenId}</div>
                    <div>LV.{this.state.token1[0]}</div>
                    <div>
                      ATK:10 DEF:100 AB:100% <br />
                      DB:100% AD:100% DD:200%
                    </div>
                  </div>
                  <div
                    className="upgrade_remove"
                    onClick={this.removeHero.bind(this, 1)}
                  >
                    Remove
                  </div>
                </div>
              ) : (
                <div>Please select a hero</div>
              )}
            </div>
            <div className="upgradeTokenB">
              <p className="upgradeTokenATitle">Hero 2</p>
              {this.state.token2 != "" ? (
                <div className="upgradeTokenAInfo">
                  <img
                    src={
                      HeroData.get(parseInt(this.state.token2[1])).images[
                      parseInt(  this.state.token2[0])-1
                      ]
                    }
                    className="upgradeTokenAImage"
                  />
                  <div className="upgradeTokenADetail">
                    <div>{this.state.token2.tokenId}</div>
                    <div>LV.{this.state.token2[0]}</div>
                    <div>
                      ATK:10 DEF:100 AB:100% <br />
                      DB:100% AD:100% DD:200%
                    </div>
                  </div>
                  <div
                    className="upgrade_remove"
                    onClick={this.removeHero.bind(this, 2)}
                  >
                    Remove
                  </div>
                </div>
              ) : (
                <div>Please select a hero</div>
              )}
            </div>

            {this.state.token1 != "" && this.state.token2 != "" ? (
              this.state.token1[1] == this.state.token2[1] ? (
                this.state.token1[0] == this.state.token2[0] ? (
                  this.state.token1[0] < 5 ? (
                    <div>
                      <div className="upgradeTokenC">
                        <p className="upgradeTokenATitle">You will get</p>
                        <div className="upgradeTokenAInfo">
                          <img
                            src={
                              HeroData.get(parseInt(this.state.token1[1]))
                                .images[parseInt(this.state.token1[0])]
                            }
                            className="upgradeTokenAImage"
                          />
                          <div className="upgradeTokenADetail">
                            <div>Race</div>
                            <div>LV.{parseInt(this.state.token1[0]) + 1}</div>
                            <div>
                              ATK:10 DEF:100 AB:100% <br />
                              DB:100% AD:100% DD:200%
                            </div>
                          </div>
                        </div>
                      </div>
                      <div onClick={this.upgrade.bind(this)}>Upgrade</div>
                    </div>
                  ) : (
                    <div>Level 5 is the highest level of a hero</div>
                  )
                ) : (
                  <div>Please select two heroes of the same level</div>
                )
              ) : (
                <div>Please select two heroes of the same race</div>
              )
            ) : (
              <div></div>
            )}
          </div>
          <div className="upgrade_right">
            <p className="upgrade_right_tit">My Heroes</p>
            <ul className="upgradeHeroList">
              {this.state.myheroes.map((item, index) => (
                <li
                  className="upgradeHeroItem"
                  key={index}
                  onClick={this.selectHero.bind(this, index)}
                >
                  <img
                    src={HeroData.get(parseInt(item[1])).images[parseInt(item[0])-1]}
                    width={120}
                  />

                  <div>select</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default UpgradeHero;
