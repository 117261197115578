import React, { Component } from "react";

import "./GameHome.css";
import NPC1 from "../../assets/NPC1.gif";
import NPC2 from "../../assets/NPC2.gif";
import NPC3 from "../../assets/NPC3.gif";
import NPC4 from "../../assets/NPC4.gif";
import npcplayicon0 from "../../assets/npcplay_icon0.png";
import npcplayicon1 from "../../assets/npcplay_icon1.png";
import npcplayicon2 from "../../assets/npcplay_icon2.png";
import npcplayicon3 from "../../assets/npcplay_icon3.png";
import npcplayicon4 from "../../assets/npcplay_icon4.png";
import TwitterIcon from "../../assets/twittericon.png";
import GuildIcon from "../../assets/guildicon.png";
import TwitterBlackIcon from "../../assets/twittericon_black.png";
import GuildBlackIcon from "../../assets/guildicon_black.png";
import hero_icon1 from "../../assets/hero_icon1.png";
import hero_icon2 from "../../assets/hero_icon2.png";
import hero_icon3 from "../../assets/hero_icon3.png";
import hero_icon4 from "../../assets/hero_icon4.png";
import hero_icon5 from "../../assets/hero_icon5.png";
import hero_icon6 from "../../assets/hero_icon6.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import arbitrum from "../../assets/logo/arbitrum.svg"
import avalanche from "../../assets/logo/avalanche.svg"
import base from "../../assets/logo/base.svg"
import bnb from "../../assets/logo/bnb.svg"
import optimism from "../../assets/logo/optimism.svg"
import polygon from "../../assets/logo/polygon.svg"
import scroll from "../../assets/logo/scroll.svg"
import "react-tabs/style/react-tabs.css";

class GameHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideStory: true,
      hideRaces: true,
      hidePlay: true,
      hideCommunity: true,
    };
  }

  showStory = () => {
    this.setState({
      hideStory: false,
      hideRaces: true,
      hidePlay: true,
      hideCommunity: true,
    });
  };
  showRaces = () => {
    this.setState({
      hideRaces: false,
      hideStory: true,
      hidePlay: true,
      hideCommunity: true,
    });
  };
  showPlay = () => {
    this.setState({
      hidePlay: false,
      hideStory: true,
      hideRaces: true,
      hideCommunity: true,
    });
  };
  showCommunity = () => {
    this.setState({
      hideCommunity: false,
      hideStory: true,
      hideRaces: true,
      hidePlay: true,
    });
  };
  closeNPCMessage = () => {
    this.setState({
      hideStory: true,
      hideRaces: true,
      hidePlay: true,
      hideCommunity: true,
    });
  };

  getNPCMessageTit = () => {
    if (!this.state.hideStory) {
      return "Game Story";
    } else if (!this.state.hideRaces) {
      return "Attributes and Races";
    } else if (!this.state.hidePlay) {
      return "How to Play";
    } else if (!this.state.hideCommunity) {
      return "Join Community";
    }
  };
  render() {
    return (
      <div className="bg0">
        {/* <div className="bg3"></div> */}
        <div className="bg2"></div>
        <div className="bg1"></div>
        {!this.state.hideStory ||
        !this.state.hideRaces ||
        !this.state.hidePlay ||
        !this.state.hideCommunity ? (
          <div className="npc_message_bg">
            <div className="npc_message">
              <div className="npc_message_header">
                <div className="npc_message_tit">{this.getNPCMessageTit()}</div>

                <div
                  className="npc_message_close"
                  onClick={this.closeNPCMessage}
                >
                  Close
                </div>
              </div>

              <div
                className="npc_message_story_content"
                hidden={this.state.hideStory}
              >
                Hello heroes, welcome to Ether War. A long time ago, multiple
                races lived on the continent of Ether. Some of these races are
                strong orcs, some are clever elves, and brave humans etc. <br />
                <br />
                This continent was once peaceful and prosperous, but a crisis
                changed everything. Evil forces shattered the cup that
                symbolizes consensus. The God's Cup was fragmented into diamonds
                and scattered on the continent. The powerful magic of the
                diamonds froze the various races living on the Ether continent.
                The heroes fell asleep, and the entire continent was lifeless.{" "}
                <br />
                <br />
                Now you need to unseal them and wake up these sleeping heroes.
                Inspire their fighting spirit and confidence through battles,
                rebuild the civilization of the ether continent, and restore it
                to the same peace and prosperity as before.
                <br />
                <br />
                Good luck!
              </div>

              <div
                className="npc_message_races_content"
                hidden={this.state.hideRaces}
              >
                <div className="npc_message_races_content_tit">Attributes</div>
                <ul className="npc_message_races_attributes">
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon1}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">Attack(ATK)</span>
                      <br />
                      <span className="npc_attributes_description">
                        Attack power is a basic attribute, high attack power
                        will cause more damage to the opponent team.
                      </span>
                    </p>
                  </li>
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon2}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">Defense(DFE)</span>
                      <br />
                      <span className="npc_attributes_description">
                        Defense is a basic attribute, and a high defense can
                        resist more damage from the opposing team.
                      </span>
                    </p>
                  </li>
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon3}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">
                        Attack Buff(AB)
                      </span>
                      <br />
                      <span className="npc_attributes_description">
                        Attack Buff can increase the percentage of our team's
                        attack power.
                      </span>
                    </p>
                  </li>
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon4}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">
                        Defense Buff(DB)
                      </span>
                      <br />
                      <span className="npc_attributes_description">
                        Defense Buff can increase the percentage of our team's
                        defense.
                      </span>
                    </p>
                  </li>
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon5}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">
                        Attack Debuff(AD)
                      </span>
                      <br />
                      <span className="npc_attributes_description">
                        Attack Debuff can reduce the percentage of the opposing
                        team's attack power.
                      </span>
                    </p>
                  </li>
                  <li className="npc_attributes">
                    <div className="npc_attributes_img">
                      <img
                        src={hero_icon6}
                        width={32}
                        className="npc_attributes_image"
                      />
                    </div>
                    <p>
                      <span className="npc_attributes_name">
                        Defense Debuff(DD)
                      </span>
                      <br />
                      <span className="npc_attributes_description">
                        Defense Debuff can reduce the percentage of the opposing
                        team's defense.
                      </span>
                    </p>
                  </li>
                </ul>
                <div
                  className="npc_message_races_content_tit"
                  style={{ marginTop: "30px" }}
                >
                  Races
                </div>
                <Tabs>
                  <TabList>
                    <Tab>Humans</Tab>
                    <Tab>Elves</Tab>
                    <Tab>Orcs</Tab>
                    <Tab>Demons</Tab>
                    <Tab>Undeads</Tab>
                    <Tab>Dwarfs</Tab>
                    <Tab>Foresters</Tab>
                    <Tab>HolyWarriors</Tab>
                    <Tab>Traitors</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They are the largest race in the Ether continent, very
                        suitable for new players, their attack and defense are
                        medium and average.
                      </p>
                      <ul>
                        <li>ATK:(50-75)</li>
                        <li>DEF:(50-75)</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They have a handsome appearance and high attack power,
                        but their low defense is their weakness.
                      </p>
                      <ul>
                        <li>ATK:(75-100)</li>
                        <li>DEF:(25-50)</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They have a strong body and act as the team's
                        protectors, but their low attack power is their
                        weakness.
                      </p>
                      <ul>
                        <li>ATK:(25-50)</li>
                        <li>DEF:(75-100)</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They come from hell and possess evil powers that can
                        make the entire team violent.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:(25-75)</li>
                        <li>AB:(25%-75%)</li>
                        <li>DB:0</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They are like ghosts. Travel freely in the Ether
                        continent. When you invite them to join the team, they
                        will use dark magic to improve the defense of the team.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:(25-75)</li>
                        <li>AB:0</li>
                        <li>DB:(25%-75%)</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        Although they are short, they are good at sneak
                        attacking opponents.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:(25-75)</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:(25%-75%)</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They live in forests, are vegetarians, and they use
                        natural magic to remove the armor of their opponents.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:(25-75)</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:0</li>
                        <li>DD:(25%-75%)</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They exude a divine light, and when illuminated by this
                        light, you will gain confidence and will to fight.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:0</li>
                        <li>AB:(25%-75%)</li>
                        <li>DB:(25%-75%)</li>
                        <li>AD:0</li>
                        <li>DD:0</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="npc_message_races_item">
                      <p>
                        They are traitors who split from the human race. They
                        betrayed the Ether continent for wealth. Be careful when
                        you see them.
                      </p>
                      <ul>
                        <li>ATK:0</li>
                        <li>DEF:0</li>
                        <li>AB:0</li>
                        <li>DB:0</li>
                        <li>AD:(25%-75%)</li>
                        <li>DD:(25%-75%)</li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>

              <div
                className="npc_message_play_content"
                hidden={this.state.hidePlay}
              >
                <div className="npc_message_play_content_tit">
                  The game is still under development, but you can get some
                  information in advance
                </div>
                <ul className="npc_message_play_list">
                  <li>
                    <img src={npcplayicon0} width={32} />
                    &nbsp;
                    <p>
                      You need to connect your Metamask to the

                      <span className="npc_message_play_tip">
                      <a href="https://www.bnbchainlist.org/" target="_blank"> opBNB Testnet</a>{" "}
                      </span>
                      
                      or

                      <span className="npc_message_play_tip">
                        {" "}
                        <a href="https://sepolia.scroll.io/portal" target="_blank"> Scroll Testnet</a>{" "}
                      </span>
                     
                  
                      
                    </p>
                  </li>
                  <li>
                    <img src={npcplayicon1} width={32} />
                    &nbsp;
                    <p>
                      Go to{" "}
                      <span className="npc_message_play_tip">
                        <a href="/mint" target="_blank">
                          Mint
                        </a>
                      </span>
                      , there are many different heroes, heroes of different
                      races have different attributes, and the values of
                      attributes are random, you can choose your favorite to
                      mint, the price of each hero is 0.005eth, and each
                      transaction can mint up to 20 heroes
                    </p>
                  </li>
                  <li>
                    <img src={npcplayicon2} width={32} />
                    &nbsp;
                    <p>
                      Go to
                      <span className="npc_message_play_tip">
                        <a href="/heroes" target="_blank">
                          &nbsp;My Heroes
                        </a>
                      </span>{" "}
                      and you can view your heroes.
                    </p>
                  </li>
                  <li>
                    <img src={npcplayicon3} width={32} />
                    &nbsp;
                    <p>
                      To create a team, you will team up your heroes. Customize
                      the team name and freely combine the heroes you own. Each
                      team has at least one hero and a maximum of three heroes.
                    </p>
                  </li>
                  <li>
                    <img src={npcplayicon4} width={32} />
                    &nbsp;
                    <p>
                      To
                      <span className="npc_message_play_tip">
                        <a href="/fight" target="_blank">
                          &nbsp;fight
                        </a>
                      </span>{" "}
                      , the game system will randomly match opponents for you,
                      and the winner will be rewarded with points. Each hero can
                      automatically get 3 spirit points every day, and each
                      battle will consume one spirit point. When the hero's
                      spirit value is 0, the hero will have no effect in the
                      team.
                    </p>
                  </li>
                </ul>
              </div>

              <div
                className="npc_message_community_content"
                hidden={this.state.hideCommunity}
              >
                <div className="npc_message_community_content_tit">
                  Welcome to join our community to learn about the latest
                  developments
                </div>
                <div className="npc_message_community_btns">
                  <a href="https://twitter.com/EtherWarGame" target="_blank">
                    <div className="npc_message_community_btn">
                      <img src={TwitterIcon} width={32} />
                      &nbsp; Twitter
                    </div>
                  </a>
                  <a href="https://guild.xyz/etherwar" target="_blank">
                    <div className="npc_message_community_btn">
                      <img src={GuildIcon} width={32} />
                      &nbsp; Guild
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="gamehome_boder_bg">
          <div className="gamehome_boder">
            <div className="game_home">
              <div className="game_home_bigtit">Omnichain and fully onchain game</div>
       

              {/* <ul className="game_home_btns">
                <li>
                  <a href="/mint" >
                    <div className="game_home_btn">Free Mint</div>
                  </a>
                </li>
                <li>
                  <a href="/heroes" >
                    <div className="game_home_btn">Create Team</div>
                  </a>
                </li>
                <li>
                  <a href="/fight" >
                    <div className="game_home_btn">Fight</div>
                  </a>
                </li>
              </ul> */}
              
              <div className="game_home_npc_tit">Chains</div>
              <ul className="support_chains">
                <li><img src={arbitrum} width={32}/></li>
                <li><img src={avalanche} width={32}/></li>
                <li><img src={base} width={32}/></li>
                <li><img src={bnb} width={32}/></li>
                <li><img src={optimism} width={32}/></li>
                <li><img src={polygon} width={32}/></li>
                <li><img src={scroll} width={32}/></li>
              </ul>
              <div className="game_home_npc_tit">NPCs</div>
              <div className="game_home_npc_description">
                They will guide you how to play and learn more about EtherWar
              </div>
              <ul className="game_home_npc_list">
                <li className="game_home_npc">
                  <div className="npc_img" onClick={this.showStory}>
                    <img src={NPC1} width={120} />
                  </div>
                  <div className="npc_name">Sedona</div>
                  <div className="npc_description">
                    I will tell you the
                    <span className="npc_description_tip"> story</span> of
                    EtherWar
                  </div>
                </li>
                <li className="game_home_npc">
                  <div className="npc_img" onClick={this.showRaces}>
                    <img src={NPC2} width={120} />
                  </div>
                  <div className="npc_name">Jerome</div>
                  <div className="npc_description">
                    I will introduce you to
                    <span className="npc_description_tip"> races</span> and
                    attributes
                  </div>
                </li>

                <li className="game_home_npc">
                  <div className="npc_img" onClick={this.showPlay}>
                    <img src={NPC4} width={120} />
                  </div>
                  <div className="npc_name">Juria</div>
                  <div className="npc_description">
                    I will show you how to
                    <span className="npc_description_tip"> play </span>
                    game
                  </div>
                </li>

                <li className="game_home_npc">
                  <div className="npc_img" onClick={this.showCommunity}>
                    <img src={NPC3} width={120} />
                  </div>
                  <div className="npc_name">Hinata </div>
                  <div className="npc_description">
                    I will show you how to join our
                    <span className="npc_description_tip"> community</span>
                  </div>
                </li>
              </ul>

              <ul className="social_link">
                <li>
                  <a href="https://twitter.com/EtherWarGame" target="_blank">
                    <img src={TwitterBlackIcon} width={36} />
                    <p>Twitter</p>
                  </a>
                </li>
                <li>
                  <a href="https://guild.xyz/etherwar" target="_blank">
                    <img src={GuildBlackIcon} width={32} />
                    <p>Guild</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="gamehome_top">
          <div className="gamehome_top_left"></div>
          <div className="gamehome_top_center"></div>
          <div className="gamehome_top_right"></div>
        </div>
        <div className="gamehome_bottom"></div> */}
      </div>
    );
  }
}
export default GameHome;
