import Human1 from "../assets/heroes/Human/1.gif" 
import Human2 from "../assets/heroes/Human/2.gif" 
import Human3 from "../assets/heroes/Human/3.gif" 
import Human4 from "../assets/heroes/Human/4.gif" 
import Human5 from "../assets/heroes/Human/5.gif" 

import Elve1 from "../assets/heroes/Elves/1.gif"
import Elve2 from "../assets/heroes/Elves/2.gif"
import Elve3 from "../assets/heroes/Elves/3.gif"
import Elve4 from "../assets/heroes/Elves/4.gif"
import Elve5 from "../assets/heroes/Elves/5.gif"

import Orac1 from "../assets/heroes/Oracs/1.gif"
import Orac2 from "../assets/heroes/Oracs/2.gif"
import Orac3 from "../assets/heroes/Oracs/3.gif"
import Orac4 from "../assets/heroes/Oracs/4.gif"
import Orac5 from "../assets/heroes/Oracs/5.gif"

import Demon1 from "../assets/heroes/Demons/1.gif"
import Demon2 from "../assets/heroes/Demons/2.gif"
import Demon3 from "../assets/heroes/Demons/3.gif"
import Demon4 from "../assets/heroes/Demons/4.gif"
import Demon5 from "../assets/heroes/Demons/5.gif"

import Undead1 from "../assets/heroes/Undead/1.gif"
import Undead2 from "../assets/heroes/Undead/2.gif"
import Undead3 from "../assets/heroes/Undead/3.gif"
import Undead4 from "../assets/heroes/Undead/4.gif"
import Undead5 from "../assets/heroes/Undead/5.gif"

import Dwarf1 from "../assets/heroes/Dwarfs/1.gif"
import Dwarf2 from "../assets/heroes/Dwarfs/2.gif"
import Dwarf3 from "../assets/heroes/Dwarfs/3.gif"
import Dwarf4 from "../assets/heroes/Dwarfs/4.gif"
import Dwarf5 from "../assets/heroes/Dwarfs/5.gif"

import Forester1 from "../assets/heroes/Foresters/1.gif"
import Forester2 from "../assets/heroes/Foresters/2.gif"
import Forester3 from "../assets/heroes/Foresters/3.gif"
import Forester4 from "../assets/heroes/Foresters/4.gif"
import Forester5 from "../assets/heroes/Foresters/5.gif"

import HolyWarrior1 from "../assets/heroes/HolyWarriors/1.gif"
import HolyWarrior2 from "../assets/heroes/HolyWarriors/2.gif"
import HolyWarrior3 from "../assets/heroes/HolyWarriors/3.gif"
import HolyWarrior4 from "../assets/heroes/HolyWarriors/4.gif"
import HolyWarrior5 from "../assets/heroes/HolyWarriors/5.gif"

import Robber1 from "../assets/heroes/Robbers/1.gif"
import Robber2 from "../assets/heroes/Robbers/2.gif"
import Robber3 from "../assets/heroes/Robbers/3.gif"
import Robber4 from "../assets/heroes/Robbers/4.gif"
import Robber5 from "../assets/heroes/Robbers/5.gif"
const HeroData = new Map([
  [
    0,
    {
      race:"Human",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Human1,Human2,Human3,Human4,Human5],
      attributes:[40,40,0,0,0,0]
    }
  ],
  [
    1,
    {
      race:"Elve",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Elve1,Elve2,Elve3,Elve4,Elve5],
      attributes:[50,30,0,0,0,0]
    }
  ],
  [
    2,
    {
      race:"Orac",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Orac1,Orac2,Orac3,Orac4,Orac5],
      attributes:[30,50,0,0,0,0]
    }
  ],
  [
    3,
    {
      race:"Demon",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Demon1,Demon2,Demon3,Demon4,Demon5],
      attributes:[30,20,10,0,0,0]
    }
  ],
  [
    4,
    {
      race:"Undead",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Undead1,Undead2,Undead3,Undead4,Undead5],
      attributes:[20,30,0,10,0,0]
    }
  ],
  [
    5,
    {
      race:"Dwarf",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Dwarf1,Dwarf2,Dwarf3,Dwarf4,Dwarf5],
      attributes:[40,20,0,0,5,0]
    }
  ],
  [
    6,
    {
      race:"Forester",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Forester1,Forester2,Forester3,Forester4,Forester5],
      attributes:[20,40,0,0,0,5]
    }
  ],
  [
    7,
    {
      race:"HolyWarrior",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[HolyWarrior1,HolyWarrior2,HolyWarrior3,HolyWarrior4,HolyWarrior5],
      attributes:[30,30,5,5,0,0]
    }
  ],
  [
    8,
    {
      race:"Robber",
      title:"They are the largest race in the blast continent, very suitable for new players, their attack and defense are medium and average.",
      images:[Robber1,Robber2,Robber3,Robber4,Robber5],
      attributes:[30,30,0,0,5,5]
    }
  ]
  
]);

export default HeroData;
